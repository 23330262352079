// Customizer const
export const THEME_TYPE = 'THEME_TYPE'
export const THEME_TYPE_LITE = 'THEME_TYPE_LITE'
export const THEME_TYPE_DARK = 'THEME_TYPE_DARK'
export const THEME_TYPE_SEMI_DARK = 'THEME_TYPE_SEMI_DARK'

export const THEME_COLOR = 'THEME_COLOR'
export const UPDATE_RTL_STATUS = 'UPDATE_RTL_STATUS'
export const LAYOUT_TYPE = 'LAYOUT_TYPE'

export const LAYOUT_TYPE_FRAMED = 'LAYOUT_TYPE_FRAMED'
export const LAYOUT_TYPE_BOXED = 'LAYOUT_TYPE_BOXED'
export const LAYOUT_TYPE_FULL = 'LAYOUT_TYPE_FULL'

export const NAV_STYLE = 'NAV_STYLE'

export const NAV_STYLE_FIXED = 'NAV_STYLE_FIXED'
export const NAV_STYLE_MINI_SIDEBAR = 'NAV_STYLE_MINI_SIDEBAR'
export const NAV_STYLE_DRAWER = 'NAV_STYLE_DRAWER'
export const NAV_STYLE_NO_HEADER_MINI_SIDEBAR =
    'NAV_STYLE_NO_HEADER_MINI_SIDEBAR'
export const NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR =
    'NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR'

export const NAV_STYLE_DEFAULT_HORIZONTAL = 'NAV_STYLE_DEFAULT_HORIZONTAL'
export const NAV_STYLE_DARK_HORIZONTAL = 'NAV_STYLE_DARK_HORIZONTAL'
export const NAV_STYLE_INSIDE_HEADER_HORIZONTAL =
    'NAV_STYLE_INSIDE_HEADER_HORIZONTAL'
export const NAV_STYLE_BELOW_HEADER = 'NAV_STYLE_BELOW_HEADER'
export const NAV_STYLE_ABOVE_HEADER = 'NAV_STYLE_ABOVE_HEADER'

export const LIGHT_PURPLE = 'light_purple'
export const LIGHT_PURPLE_SEC = '#00B378'
export const LIGHT_PURPLE_DARK_TEXT_COLOR = '#9799AC'

export const RED = 'red'
export const RED_SEC = '#00D9C9'
export const RED_DARK_TEXT_COLOR = '#878BAB'

export const BLUE = 'blue'
export const BLUE_SEC = '#FCB53B'
export const BLUE_DARK_TEXT_COLOR = '#AAA59A'

export const DARK_BLUE = 'dark_blue'
export const DARK_BLUE_SEC = '#17BDE5'
export const DARK_BLUE_DARK_TEXT_COLOR = '#9DDAE9'

export const ORANGE = 'orange'
export const ORANGE_SEC = '#F1D065'
export const ORANGE_DARK_TEXT_COLOR = '#ABA895'

export const LIGHT_BLUE = 'light_blue'
export const LIGHT_BLUE_SEC = '#59DCFF'
export const LIGHT_BLUE_DARK_TEXT_COLOR = '#92A2C8'

export const DEEP_ORANGE = 'deep_orange'
export const DEEP_ORANGE_SEC = '#70A288'
export const DEEP_ORANGE_DARK_TEXT_COLOR = '#97B8C7'

export const LIGHT_PURPLE_1 = 'light_purple_1'
export const LIGHT_PURPLE_1_SEC = '#E14594'
export const LIGHT_PURPLE_1_DARK_TEXT_COLOR = '#8288B4'

export const LIGHT_PURPLE_2 = 'light_purple_2'
export const LIGHT_PURPLE_2_SEC = '#64D7D6'
export const LIGHT_PURPLE_2_DARK_TEXT_COLOR = '#5782BB'

export const ACTIVE_COLOR_OPTION = '#ffffff'

export const TAB_SIZE = 992
