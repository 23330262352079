import React from 'react'
import { Layout } from 'antd'
// import Icon from '@ant-design/icons'
import { useDispatch, useSelector } from 'react-redux'
import UserInfo from 'components/UserInfo'
import HorizontalNav from '../HorizontalNav'
import { Link } from 'react-router-dom'
import { toggleCollapsedSideNav } from '../../../appRedux/actions'
import { TAB_SIZE } from '../../../constants/ThemeSetting'

const { Header } = Layout

// const menu = (
//     <Menu onClick={handleMenuClick}>
//         <Menu.Item key="1">Products</Menu.Item>
//         <Menu.Item key="2">Apps</Menu.Item>
//         <Menu.Item key="3">Blogs</Menu.Item>
//     </Menu>
// )

// function handleMenuClick(e) {
//     message.info('Click on menu item.')
// }

const InsideHeader = () => {
    // const [searchText, setSearchText] = useState('')
    const navCollapsed = useSelector(({ common }) => common.navCollapsed)
    const width = useSelector(({ common }) => common.width)
    // const name = useSelector((state) => state.profile.influencerName)
    const dispatch = useDispatch()

    // const updateSearchChatUser = (evt) => {
    //     setSearchText(evt.target.value)
    // }

    return (
        <div
            className="gx-header-horizontal gx-inside-header-horizontal"
            style={{ backgroundColor: '#003366' }}
        >
            <Header
                className="gx-header-horizontal-main"
                style={{ marginTop: 10 }}
            >
                <div className="gx-container">
                    <div className="gx-header-horizontal-main-flex">
                        <div className="gx-d-block gx-d-lg-none gx-linebar gx-mr-xs-3 6e">
                            <i
                                className="gx-icon-btn icon icon-menu"
                                onClick={() => {
                                    dispatch(
                                        toggleCollapsedSideNav(!navCollapsed)
                                    )
                                }}
                            />
                        </div>
                        <Link
                            to="/"
                            className="gx-d-block gx-d-lg-none gx-pointer gx-mr-xs-3 gx-pt-xs-1 gx-w-logo"
                        >
                            <img
                                alt=""
                                width="40"
                                height="40"
                                src="/assets/images/logo.png"
                            />
                        </Link>
                        <Link
                            to="/"
                            className="gx-d-none gx-d-lg-block gx-pointer gx-mr-xs-5 gx-logo"
                        >
                            <img
                                alt=""
                                width="40"
                                height="40"
                                src="/assets/images/logo.png"
                            />
                        </Link>
                        {width >= TAB_SIZE && (
                            <div className="gx-header-horizontal-nav gx-header-horizontal-nav-curve">
                                <HorizontalNav />
                            </div>
                        )}
                        <ul className="gx-header-notifications gx-ml-auto">
                            {/* <li className="gx-notify gx-notify-search">
                                <Popover
                                    overlayClassName="gx-popover-horizantal"
                                    placement="bottomRight"
                                    content={
                                        <div className="gx-d-flex">
                                            <Dropdown overlay={menu}>
                                                <Button>
                                                    Category{' '}
                                                    <Icon type="down" />
                                                </Button>
                                            </Dropdown>
                                            <SearchBox
                                                styleName="gx-popover-search-bar"
                                                placeholder="Search in app..."
                                                onChange={updateSearchChatUser}
                                                value={searchText}
                                            />
                                        </div>
                                    }
                                    trigger="click"
                                >
                                    <span className="gx-pointer gx-d-block">
                                        <i className="icon icon-search-new" />
                                    </span>
                                </Popover>
                            </li>

                            <li className="gx-notify">
                                <Popover
                                    overlayClassName="gx-popover-horizantal"
                                    placement="bottomRight"
                                    content={<AppNotification />}
                                    trigger="click"
                                >
                                    <span className="gx-pointer gx-d-block">
                                        <i className="icon icon-notification" />
                                    </span>
                                </Popover>
                            </li> */}
                            <li className="gx-user-nav">
                                <UserInfo />
                            </li>
                        </ul>
                    </div>
                </div>
            </Header>
        </div>
    )
}

export default InsideHeader
