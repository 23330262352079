import React from 'react'
import { Layout } from 'antd'
import { Link } from 'react-router-dom'
import { toggleCollapsedSideNav } from '../../appRedux/actions'
import UserInfo from '../../components/UserInfo'
import Auxiliary from 'util/Auxiliary'

import {
    NAV_STYLE_DRAWER,
    NAV_STYLE_FIXED,
    NAV_STYLE_MINI_SIDEBAR,
    TAB_SIZE,
} from '../../constants/ThemeSetting'
import { useDispatch, useSelector } from 'react-redux'

const { Header } = Layout

const Topbar = () => {
    const { navStyle } = useSelector(({ settings }) => settings)
    const navCollapsed = useSelector(({ common }) => common.navCollapsed)
    const width = useSelector(({ common }) => common.width)
    // const [searchText, setSearchText] = useState('');
    const dispatch = useDispatch()

    return (
        <Header>
            {navStyle === NAV_STYLE_DRAWER ||
            ((navStyle === NAV_STYLE_FIXED ||
                navStyle === NAV_STYLE_MINI_SIDEBAR) &&
                width < TAB_SIZE) ? (
                <div className="gx-linebar gx-mr-3">
                    <i
                        className="gx-icon-btn icon icon-menu"
                        onClick={() => {
                            dispatch(toggleCollapsedSideNav(!navCollapsed))
                        }}
                    />
                </div>
            ) : null}
            <Link
                to="/dashboard"
                className="gx-d-block gx-d-lg-none gx-pointer"
            >
                <img
                    alt=""
                    width="40"
                    height="40"
                    src={'/assets/images/logo.png'}
                />
            </Link>
            <ul className="gx-header-notifications gx-ml-auto">
                <li className="gx-notify gx-notify-search gx-d-inline-block gx-d-lg-none"></li>
                {/* {width >= TAB_SIZE ? null :
          <Auxiliary>
            <li className="gx-notify">
              <Popover overlayClassName="gx-popover-horizantal" placement="bottomRight" content={<AppNotification/>}
                       trigger="click">
                <span className="gx-pointer gx-d-block"><i className="icon icon-notification"/></span>
              </Popover>
            </li>
          </Auxiliary>
        } */}
                {width >= TAB_SIZE ? null : (
                    <Auxiliary>
                        <li className="gx-user-nav">
                            <UserInfo />
                        </li>
                    </Auxiliary>
                )}
            </ul>
        </Header>
    )
}

export default Topbar
