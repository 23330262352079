import React from 'react'
import IntlMessages from 'util/IntlMessages'
import { useAuth } from '../authentication'
import AppNotificationContainer from '../components/AppNotificationContainer'
import TelegramLoginButton from 'react-telegram-login'
import { fetchSuccess } from '../appRedux/actions'

const SignIn = () => {
    const { isLoading, userLogin } = useAuth()

    const handleTelegramResponse = (response) => {
        response.auth_date = response.auth_date.toString()
        userLogin(response)
        fetchSuccess()
    }

    const botId = process.env.REACT_APP_TG_BOT_ID

    return (
        <div className="gx-app-login-wrap">
            <div className="gx-app-login-container">
                <div className="gx-app-login-main-content">
                    <div className="gx-app-logo-content">
                        <div className="gx-app-logo-content-bg" />
                        <div className="gx-app-logo-wid">
                            <h1>
                                <IntlMessages id="app.userAuth.signIn" />
                            </h1>
                            <p>
                                <IntlMessages id="app.userAuth.bySigning" />
                            </p>
                            <p>
                                <IntlMessages id="app.userAuth.getAccount" />
                            </p>
                        </div>
                    </div>
                    <div className="gx-app-login-content">
                        <TelegramLoginButton
                            dataOnauth={handleTelegramResponse}
                            botName={botId}
                        />
                    </div>
                    <AppNotificationContainer loading={isLoading} />
                </div>
            </div>
        </div>
    )
}

export default SignIn
