export const setProfileId = (userId) => {
    return {
        type: 'SET_PROFILE_ID',
        payload: userId,
    }
}

export const setProfileName = (name) => {
    return {
        type: 'SET_PROFILE_NAME',
        payload: name,
    }
}

export const setProfilePhoto = (url) => {
    return {
        type: 'SET_PROFILE_PHOTO_URL',
        payload: url,
    }
}

export const setProfileStatus = (status) => {
    return {
        type: 'SET_PROFILE_STATUS',
        payload: status,
    }
}

export const setProfileMetrics = (metrics) => {
    return {
        type: 'SET_PROFILE_METRICS',
        payload: metrics,
    }
}

export const setOnboardingStatus = (onboardingStatus) => {
    return {
        type: 'SET_ONBOARDING_STATUS',
        payload: onboardingStatus,
    }
}

export const setProfileTopPlans = (plans) => {
    return {
        type: 'SET_PROFILE_TOP_PLANS',
        payload: plans,
    }
}

export const setProfileUsername = (username) => {
    return {
        type: 'SET_PROFILE_USERNAME',
        payload: username,
    }
}

export const setProfileAbout = (about) => {
    return {
        type: 'SET_PROFILE_ABOUT',
        payload: about,
    }
}

export const setProfileMobileNumber = (number) => {
    return {
        type: 'SET_PROFILE_MOBILE',
        payload: number,
    }
}

export const setLandingPageUrl = (url) => {
    return {
        type: 'SET_LANDING_PAGE_URL',
        payload: url,
    }
}

export const setInfluencerBotDetails = (bot) => {
    return {
        type: 'SET_BOT_DETAILS',
        payload: bot,
    }
}
