import React, { useEffect, useState } from 'react'
import { Button, Col, Row, Typography } from 'antd'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import CardBox from 'components/CardBox/index'
import ContainerHeader from 'components/ContainerHeader/index'
import AppNotificationContainer from '../../../components/AppNotificationContainer'
import { useHistory } from 'react-router-dom'
import { useProvideTralkUserAuth } from '../../../authenticationTralk/TralkAuthProvider'
import { initializeApp } from 'firebase/app'
import {
    getAuth,
    getRedirectResult,
    GoogleAuthProvider,
    signInWithRedirect,
} from 'firebase/auth'
import { FIREBASE_CONFIG } from '../../../util/FirebaseConfig'
import { axiosTralkUserClient } from '../../../util/Api'
import { TRALK_GET_PAYMENT_LINK } from '../../../util/ApiUrls'
import { PlanClickedView } from './PlanClickedView'

initializeApp(FIREBASE_CONFIG())

const Testimonials = ({ props }) => {
    const auth = getAuth()
    const { userLogin, validate, linkUser } = useProvideTralkUserAuth()
    const [loading, setLoading] = useState(false)
    const [plans, setPlans] = useState([])
    const [planClicked, setPlanClicked] = useState({ yes: false, plan: {} })
    const [disclaimer, setDisclaimer] = useState(false)
    const [getNumber, setGetNumber] = useState()
    const [phoneNumber, setPhoneNumber] = useState(
        localStorage.getItem('userMobile')
            ? localStorage.getItem('userMobile')
            : ''
    )
    const [email, setEmail] = useState(null)
    const [codeAvailable, setCodeAvailable] = useState(false)
    const [codeDetails, setCodeDetails] = useState({})
    const planInfoFromUrl = location.pathname.split('profile/')[1].split('/')
    const planId = planInfoFromUrl.length > 0 ? planInfoFromUrl[1] : ''
    const subPlanId = planInfoFromUrl.length > 1 ? planInfoFromUrl[2] : ''
    const [pricePlan, setPricePlan] = useState(null)
    const [freeCodeFlag, setFreeCodeFlag] = useState(false)
    const history = useHistory()

    const fetchPlans = async () => {
        setLoading(true)
        const tempPlan = { ...planClicked }
        if (planId !== '') {
            for (const plan of props.plans) {
                if (plan.key === planId) tempPlan.plan = plan
            }
            tempPlan.yes = true
        }
        setPlanClicked(tempPlan)
        props.setPlanSelect(true)
        if (subPlanId !== undefined && subPlanId.length > 1) {
            for (const subPlan of tempPlan.plan.pricePlans) {
                if (subPlanId === subPlan._id) {
                    setPricePlan(subPlan)
                }
            }
        }
        if (tempPlan.yes && tempPlan.plan.pricePlans.length === 1) {
            const item = tempPlan.plan.pricePlans[0]
            if (planInfoFromUrl.length === 3) {
                history.push(item._id)
            } else {
                history.push(location.pathname + '/' + item._id)
            }
            setPricePlan(item)
        }
        setPlans(props.plans)
        setLoading(false)
    }

    const getMinPrice = (subPlans) => {
        let minPrice = 9999999
        for (const subPlan of subPlans) {
            if (subPlan.price < minPrice) minPrice = subPlan.price
        }
        return minPrice
    }

    const handleBack = () => {
        let backUrl = ''
        let repeat = planInfoFromUrl.length
        while (repeat > 1) {
            backUrl = backUrl + '../'
            repeat = repeat - 1
        }
        history.push(backUrl)
    }

    const validSelection = () => {
        if (!pricePlan) {
            return 'Please select a price plan'
        }
        if (!disclaimer) {
            return 'Please accept disclaimer'
        }

        return ''
    }

    const validNumber = () => {
        if (!/^[6789]\d{9}$/.test(phoneNumber)) {
            localStorage.setItem(
                'userError',
                'Please enter valid 10 digit number'
            )
            window.dispatchEvent(new Event('userError'))
            return false
        }
        return true
    }

    const goToPayment = async () => {
        if (!validNumber()) {
            return
        }
        try {
            localStorage.setItem('userMobile', phoneNumber)
            let payload = {
                customerDetails: {
                    customerPhone: '+91' + phoneNumber,
                    customerEmail: email,
                },
                planId: planId,
                subPlanId: pricePlan._id,
            }
            if (localStorage.getItem('coupon')) {
                payload = {
                    customerDetails: {
                        customerPhone: '+91' + phoneNumber,
                        customerEmail: email,
                    },
                    planId: planId,
                    subPlanId: pricePlan._id,
                    discountCouponCode: localStorage.getItem('coupon'),
                }
                localStorage.removeItem('coupon')
            }
            const data = await axiosTralkUserClient.post(
                TRALK_GET_PAYMENT_LINK,
                payload
            )

            let codeFound = false
            for (const item of data.data.unusedAccessCodes.documents) {
                if (item.subscriptionPlan === planId) {
                    setCodeDetails(item)
                    setCodeAvailable(true)
                    codeFound = true
                    break
                }
            }

            if (data.data.telegramJoinLink !== '') {
                setCodeDetails({
                    paymentLink: {
                        telegramJoinLink: data.data.telegramJoinLink,
                    },
                })
                setCodeAvailable(true)
                setFreeCodeFlag(true)
            } else if (!codeFound) {
                setTimeout(() => {
                    window.location.href = data.data.paymentLink.paymentLinkUrl
                }, 1000)
            }
        } catch (e) {
            console.log(e)
        }
    }

    const tralkLogin = async (redirect = false) => {
        setFreeCodeFlag(false)
        setCodeAvailable(false)
        const validator = validSelection()
        if (validator !== '' && !redirect) {
            localStorage.setItem('userError', validator)
            window.dispatchEvent(new Event('userError'))
            return
        }
        if (localStorage.getItem('userEmail')) {
            setEmail(localStorage.getItem('userEmail'))
        }
        let getNumberTemp = true
        const validation = await validate()
        if (!validation) {
            getNumberTemp = false
            try {
                await signInWithRedirect(auth, new GoogleAuthProvider())
                getNumberTemp = true
            } catch (e) {
                console.log(e)
            }
        }
        setGetNumber(getNumberTemp)
    }
    const fetchRedirectAuth = async () => {
        setLoading(true)
        const data = await getRedirectResult(auth)
        if (data) {
            localStorage.setItem('userEmail', data.user.email)
            await userLogin(data.user.accessToken)
            const validation = await validate()
            if (validation) {
                setDisclaimer(true)
                await tralkLogin(true)
            }
        }
        setLoading(false)
    }

    useEffect(() => {
        fetchPlans()
        fetchRedirectAuth()
    }, [])
    return (
        <>
            {loading ? (
                <AppNotificationContainer loading={loading} />
            ) : (
                <>
                    <div
                        className="gx-main-content"
                        style={{ overflowX: 'hidden', position: 'relative' }}
                    >
                        {planClicked.yes ? (
                            <PlanClickedView
                                handleBack={handleBack}
                                setPricePlan={setPricePlan}
                                setPlanClicked={(value) => {
                                    props.setPlanSelect(value.yes)
                                    setPlanClicked(value)
                                }}
                                setDisclaimer={setDisclaimer}
                                tralkLogin={tralkLogin}
                                setGetNumber={setGetNumber}
                                setPhoneNumber={setPhoneNumber}
                                goToPayment={goToPayment}
                                phoneNumber={() => phoneNumber}
                                planClicked={() => planClicked}
                                pricePlan={() => pricePlan}
                                planInfoFromUrl={() => planInfoFromUrl}
                                getNumber={() => getNumber}
                                codeAvailable={() => codeAvailable}
                                codeDetails={() => codeDetails}
                                freeCodeFlag={() => freeCodeFlag}
                            />
                        ) : (
                            <div
                                style={{
                                    marginLeft: '5%',
                                    marginTop: 10,
                                    width: '90%',
                                }}
                            >
                                <ContainerHeader
                                    match={props.match}
                                    title={
                                        <Typography.Text
                                            style={{
                                                fontFamily: 'Plus Jakarta Sans',
                                                fontWeight: '700',
                                                fontSize: '16px',
                                                color: '#130F26',
                                            }}
                                        >
                                            Premium Plans
                                        </Typography.Text>
                                    }
                                />
                                <Row>
                                    <Col span={24}>
                                        {plans.map((item) => {
                                            return (
                                                <div key={item.id}>
                                                    <Typography.Title level={3}>
                                                        {item.planName}
                                                    </Typography.Title>
                                                    <div
                                                        style={{
                                                            position:
                                                                'absolute',
                                                            right: '0%',
                                                            bottom: '95%',
                                                            textAlign: 'center',
                                                        }}
                                                    >
                                                        {/* <Decoration /> */}
                                                    </div>
                                                    <CardBox
                                                        mainStyle={{
                                                            backgroundColor:
                                                                '#F6F8F9',
                                                            borderRadius:
                                                                '20px',
                                                            boxShadow: 'none',
                                                        }}
                                                        heading={
                                                            <Typography.Text
                                                                style={{
                                                                    fontFamily:
                                                                        'Plus Jakarta Sans',
                                                                    fontWeight:
                                                                        '700',
                                                                    fontSize:
                                                                        '20px',
                                                                    color: '#130F26',
                                                                }}
                                                            >
                                                                {/* {item.planName} */}
                                                                Details
                                                            </Typography.Text>
                                                        }
                                                    >
                                                        {item.details.map(
                                                            (detail, index) =>
                                                                index >
                                                                3 ? null : detail.length >
                                                                  0 ? (
                                                                    <Typography.Paragraph
                                                                        style={{
                                                                            fontFamily:
                                                                                'Plus Jakarta Sans',
                                                                            fontWeight:
                                                                                '500',
                                                                            fontSize:
                                                                                '14px',
                                                                            color: '#130F26',
                                                                            letterSpacing:
                                                                                '-0.01em',
                                                                        }}
                                                                        key={
                                                                            detail +
                                                                            item.id
                                                                        }
                                                                    >
                                                                        •{' '}
                                                                        {detail}
                                                                    </Typography.Paragraph>
                                                                ) : null
                                                        )}
                                                        <div
                                                            style={{
                                                                marginTop: 35,
                                                            }}
                                                        >
                                                            <Button
                                                                onClick={() => {
                                                                    const planClickedCopy =
                                                                        {
                                                                            ...planClicked,
                                                                        }
                                                                    planClickedCopy.yes = true
                                                                    planClickedCopy.plan =
                                                                        item
                                                                    history.push(
                                                                        item.key
                                                                    )
                                                                    props.setPlanSelect(
                                                                        true
                                                                    )
                                                                    setPlanClicked(
                                                                        planClickedCopy
                                                                    )
                                                                    if (
                                                                        planClickedCopy
                                                                            .plan
                                                                            .pricePlans
                                                                            .length ===
                                                                        1
                                                                    ) {
                                                                        const item =
                                                                            planClickedCopy
                                                                                .plan
                                                                                .pricePlans[0]

                                                                        history.push(
                                                                            location.pathname +
                                                                                '/' +
                                                                                item._id
                                                                        )

                                                                        setPricePlan(
                                                                            item
                                                                        )
                                                                    }
                                                                }}
                                                                style={{
                                                                    borderRadius:
                                                                        '15px',
                                                                    borderColor:
                                                                        'transparent',
                                                                    height: 50,
                                                                    width: '90%',
                                                                    marginLeft:
                                                                        '5%',
                                                                }}
                                                            >
                                                                <Typography.Text
                                                                    style={{
                                                                        fontFamily:
                                                                            'Plus Jakarta Sans',
                                                                        fontWeight:
                                                                            '500',
                                                                        fontSize:
                                                                            '16px',
                                                                        color: '#FFB800',
                                                                    }}
                                                                >
                                                                    From
                                                                    {' ₹'}
                                                                    {getMinPrice(
                                                                        item.pricePlans
                                                                    )}
                                                                    {/* <Typography.Text
                                                                        style={{
                                                                            color: '#A1B2C3',
                                                                        }}
                                                                    >
                                                                        {' '}
                                                                        with
                                                                        Trial
                                                                    </Typography.Text> */}
                                                                </Typography.Text>
                                                            </Button>
                                                        </div>
                                                    </CardBox>
                                                </div>
                                            )
                                        })}
                                    </Col>
                                </Row>
                            </div>
                        )}
                    </div>
                </>
            )}
        </>
    )
}

export default Testimonials
