import React from 'react'
import { Menu } from 'antd'
import { Link } from 'react-router-dom'

import CustomScrollbars from 'util/CustomScrollbars'
import SidebarLogo from './SidebarLogo'
import UserProfile from './UserProfile'
import {
    NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR,
    NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
    THEME_TYPE_LITE,
} from '../../constants/ThemeSetting'
import { useSelector } from 'react-redux'

const SidebarContent = ({ sidebarCollapsed, setSidebarCollapsed }) => {
    const { navStyle, themeType } = useSelector(({ settings }) => settings)
    const pathname = useSelector(({ common }) => common.pathname)

    const getNoHeaderClass = (navStyle) => {
        if (
            navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR ||
            navStyle === NAV_STYLE_NO_HEADER_EXPANDED_SIDEBAR
        ) {
            return 'gx-no-header-notifications'
        }
        return ''
    }

    const getLinks = () => {
        // This creates a JSX element for every name in the list.
        const nameArr = [
            // { name: 'Dashboard', url: 'dashboard', icon: 'icon-dasbhoard' },
            { name: 'Channels', url: 'channels', icon: 'icon-chat' },
            { name: 'Plans & Pricing', url: 'plans', icon: 'icon-card' },
            { name: 'Members', url: 'members', icon: 'icon-contacts' },
            { name: 'Access Codes', url: 'accessCodes', icon: 'icon-family' },
            {
                name: 'Secret Messages',
                url: 'secretMessages',
                icon: 'icon-family',
            },
        ]
        return nameArr.map((name) => (
            <Menu.Item key={name.url}>
                <Link to={`/${name.url}`}>
                    <i className={`icon ${name.icon}`} />
                    <span>{name.name}</span>
                </Link>
            </Menu.Item>
        ))
    }

    return (
        <>
            <SidebarLogo
                sidebarCollapsed={sidebarCollapsed}
                setSidebarCollapsed={setSidebarCollapsed}
            />
            <div className="gx-sidebar-content">
                <div
                    className={`gx-sidebar-notifications ${getNoHeaderClass(
                        navStyle
                    )}`}
                >
                    <UserProfile />
                </div>
                <CustomScrollbars className="gx-layout-sider-scrollbar">
                    <Menu
                        selectedKeys={[pathname.split('/')[1]]}
                        theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
                        mode="inline"
                    >
                        {getLinks()}
                    </Menu>
                </CustomScrollbars>
            </div>
        </>
    )
}

export default React.memo(SidebarContent)
