import axios from 'axios'
import { INFLUENCER_REFRESH } from './ApiUrls'
let baseUrl = process.env.REACT_APP_API_URL
let baseTralkUrl = process.env.REACT_APP_TRALK_API_URL
const environment = process.env.REACT_APP_ENV

if (baseUrl.endsWith('/')) {
    baseUrl = baseUrl.substring(0, baseUrl.length - 1)
}

if (baseTralkUrl.endsWith('/')) {
    baseTralkUrl = baseTralkUrl.substring(0, baseTralkUrl.length - 1)
}

export const axiosClient = axios.create({
    baseURL: `${baseUrl}/api/`,
    headers: {
        'Content-Type': 'application/json',
    },
})

export const axiosUserClient = axios.create({
    baseURL: `${baseUrl}/api/`,
    headers: {
        'Content-Type': 'application/json',
    },
})

export const axiosTralkUserClient = axios.create({
    baseURL: `${baseTralkUrl}/`,
    headers: {
        'Content-Type': 'application/json',
    },
})

const refresh = async () => {
    try {
        console.log('refreshing')
        const data = await axiosClient.post(INFLUENCER_REFRESH)
        const authorization = `Bearer ${data.data.accessToken}`
        const refresh = data.data.refreshToken
        localStorage.setItem(
            'creds',
            JSON.stringify({ authorization, refresh })
        )
        axiosClient.defaults.headers.common = JSON.parse(
            localStorage.getItem('creds')
        )
        return true
    } catch {
        axiosClient.defaults.headers.common.Authorization = ''
        localStorage.removeItem('creds')
        return false
    }
}

axiosClient.interceptors.response.use(
    (response) => {
        return response
    },
    async (err) => {
        console.log(err.config)
        if (environment === 'dev' && err.response.status !== 401) {
            localStorage.setItem('toastError', JSON.stringify(err))
            window.dispatchEvent(new Event('error'))
        }
        if (
            err.response.status === 401 &&
            !err.config.url.includes('refresh')
        ) {
            console.log('retrying')
            console.log(axiosClient.headers)
            const refreshed = await refresh()
            if (refreshed) {
                const retryRequest = { ...err.config }
                retryRequest.headers = JSON.parse(localStorage.getItem('creds'))
                return axiosClient.request(retryRequest)
            } else {
                window.location.reload()
            }
        }

        return err
    }
)
