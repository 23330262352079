import React, { useEffect, useState } from 'react'
import { Button, Input, Space, Typography } from 'antd'
import { axiosClient } from '../../util/Api'
import { AlertMessages } from './AlertMessages'
import AppNotificationContainer from '../../components/AppNotificationContainer'
import { EyeInvisibleOutlined, EyeTwoTone } from '@ant-design/icons'
import {
    INFLUENCER_ONBOARDING_STATE_UPDATE,
    INFLUENCER_TELEGRAM_OTP_VALIDATE,
    INFLUENCER_TELEGRAM_PASSWORD_VALIDATE,
    INFLUENCER_TELEGRAM_SESSION_AUTH,
    INFLUENCER_TELEGRAM_SESSION_CHECK,
} from '../../util/ApiUrls'
import { useSelector } from 'react-redux'
import { toastMessageToUser } from '../../util/Toast'

export const SessionLogin = ({ props, setProgress, step }) => {
    const numberFromDB = useSelector(
        (state) => state.profile.influencerMobileNumber
    )
    const [phoneNumber, setPhoneNumber] = useState()
    const [code, setCode] = useState()
    const [verifying, setVerifying] = useState(false)
    const [message, setMessage] = useState()
    const [hash, setHash] = useState()
    const [isLoading, setLoading] = useState(false)
    const [passwordNeeded, setPasswordNeeded] = useState(false)
    const startVerification = async () => {
        setLoading(true)
        try {
            const data = await axiosClient.post(
                INFLUENCER_TELEGRAM_SESSION_AUTH,
                {
                    mobileNumber: '+91' + phoneNumber,
                }
            )
            setHash(data.data.phoneCodeHash)
            setVerifying(true)
            setLoading(false)
        } catch {
            setMessage('INVALID PHONE NUMBER')
            setTimeout(() => {
                setMessage('')
            }, 5000)
            setLoading(false)
        }
    }
    const finishVerification = async () => {
        setLoading(true)
        try {
            if (!passwordNeeded) {
                const data = await axiosClient.post(
                    INFLUENCER_TELEGRAM_OTP_VALIDATE,
                    {
                        mobileNumber: '+91' + phoneNumber,
                        phoneCodeHash: hash,
                        phoneCode: code,
                    }
                )
                if (data.data.message === 'SESSION_PASSWORD_NEEDED') {
                    setCode('')
                    setPasswordNeeded(true)
                } else {
                    axiosClient.post(INFLUENCER_ONBOARDING_STATE_UPDATE, {
                        status: 'PENDING',
                        onboardingStatus: 'SESSION_CREATED',
                    })
                    setProgress(1)
                }
            } else {
                const data = await axiosClient.post(
                    INFLUENCER_TELEGRAM_PASSWORD_VALIDATE,
                    {
                        mobileNumber: '+91' + phoneNumber,
                        password: code,
                    }
                )
                if (data.data === true) {
                    axiosClient.post(INFLUENCER_ONBOARDING_STATE_UPDATE, {
                        status: 'PENDING',
                        onboardingStatus: 'SESSION_CREATED',
                    })
                    setProgress(1)
                } else {
                    toastMessageToUser(
                        'Wrong password entered, please retry again.'
                    )
                    localStorage.setItem('userNumber', phoneNumber)
                    setCode('')
                    setVerifying(false)
                    setPasswordNeeded(false)
                    setLoading(false)
                }
            }
            setLoading(false)
        } catch (e) {
            console.log(e)
            setMessage('INCORRECT OTP')
            setLoading(false)
            setTimeout(() => {
                setMessage('')
            }, 5000)
        }
    }
    const sessionCheck = async () => {
        setLoading(true)
        if (localStorage.getItem('userNumber') !== null) {
            setPhoneNumber(localStorage.getItem('userNumber'))
        }
        let tempStep = 0
        try {
            const session = await axiosClient.get(
                INFLUENCER_TELEGRAM_SESSION_CHECK
            )
            if (session.data) {
                tempStep = 1
            }
        } catch {}
        if (numberFromDB !== '') {
            setPhoneNumber(numberFromDB)
        }
        setLoading(false)
        setProgress(tempStep)
    }

    useEffect(() => {
        console.log('inside session')
        sessionCheck()
        return () => {
            clearTimeout(startVerification)
            clearTimeout(finishVerification)
        }
    }, [])

    if (!verifying && step === 0) {
        return (
            <>
                {isLoading ? (
                    <AppNotificationContainer loading={isLoading} />
                ) : (
                    <>
                        <Typography.Text strong>
                            Phone Number
                            <Input
                                disabled={numberFromDB !== ''}
                                value={phoneNumber}
                                onChange={(e) => {
                                    if (e.target.value.length > 10) {
                                        // limit 10
                                        return
                                    }
                                    setPhoneNumber(e.target.value)
                                }}
                                style={{ width: 200, marginLeft: 10 }}
                            />
                        </Typography.Text>
                        <br />
                        <Button type="primary" onClick={startVerification}>
                            Get OTP
                        </Button>
                        <br />
                        <AlertMessages MESSAGE={message} />
                    </>
                )}
            </>
        )
    } else if (!passwordNeeded) {
        return (
            <>
                {isLoading ? (
                    <AppNotificationContainer loading={isLoading} />
                ) : (
                    <>
                        <Typography.Text strong>
                            Enter one time password sent to your telegram
                            account{' '}
                        </Typography.Text>
                        <br />
                        <Typography.Text>
                            <Input
                                value={code}
                                onChange={(e) => setCode(e.target.value)}
                                placeholder="input OTP"
                            />
                        </Typography.Text>
                        <br />
                        <Space>
                            <Button type="primary" onClick={finishVerification}>
                                Verify OTP
                            </Button>
                        </Space>
                        <br />
                        <AlertMessages MESSAGE={message} />
                    </>
                )}
            </>
        )
    } else {
        return (
            <>
                {isLoading ? (
                    <AppNotificationContainer loading={isLoading} />
                ) : (
                    <>
                        <Typography.Text strong>
                            Enter your account password
                        </Typography.Text>
                        <br />
                        <Typography.Text>
                            <Input.Password
                                iconRender={(visible) =>
                                    visible ? (
                                        <EyeTwoTone />
                                    ) : (
                                        <EyeInvisibleOutlined />
                                    )
                                }
                                value={code}
                                onChange={(e) => setCode(e.target.value)}
                                placeholder="input password"
                            />
                        </Typography.Text>
                        <br />
                        <Space>
                            <Button type="primary" onClick={finishVerification}>
                                Confirm
                            </Button>
                        </Space>
                        <br />
                        <AlertMessages MESSAGE={message} />
                    </>
                )}
            </>
        )
    }
}
