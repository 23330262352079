import React, { useState } from 'react'
import { Avatar, Popover } from 'antd'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'

const UserInfo = () => {
    const [isVisible, setVisible] = useState(false)
    const history = useHistory()
    const photoUrl = useSelector((state) => state.profile.photoUrl)

    const handleVisibleChange = (visible) => {
        setVisible(visible)
    }

    return (
        <Popover
            overlayClassName="gx-popover-horizantal"
            placement="bottomRight"
            onClick={() => {
                history.push('/about')
            }}
            visible={isVisible}
            onVisibleChange={handleVisibleChange}
        >
            <Avatar src={photoUrl} className="gx-avatar gx-pointer" alt="" />
        </Popover>
    )
}

export default UserInfo
