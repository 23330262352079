import React from 'react'
import { Typography } from 'antd'

const DiscountAndCoupon = ({
    item,
    pricePlan,
    planClicked,
    valid,
    couponDetails,
}) => {
    return (
        <>
            {valid ? (
                pricePlan()._id === item._id ? (
                    couponDetails.priceChanged ? (
                        <div style={{ height: 5 }} />
                    ) : (
                        <div style={{ height: 15 }} />
                    )
                ) : item?.discount ? (
                    item.discount.discountUnit === 'EXTRA_DAYS' ? (
                        <div style={{ height: 15 }} />
                    ) : (
                        <div style={{ height: 5 }} />
                    )
                ) : (
                    <div style={{ height: 25 }} />
                )
            ) : item?.discount ? (
                item.discount.discountUnit === 'EXTRA_DAYS' ? (
                    <div style={{ height: 15 }} />
                ) : (
                    <div style={{ height: 5 }} />
                )
            ) : (
                <div style={{ height: 25 }} />
            )}

            <div>
                <Typography.Text
                    strong
                    style={{
                        fontFamily: 'Plus Jakarta Sans',
                        fontWeight: '700',
                        fontSize: '14px',
                        color: '#130F26',
                    }}
                >
                    {item.numberOfDays} Days
                </Typography.Text>
            </div>
            {(valid && pricePlan()._id === item._id) ||
            (valid && pricePlan()._id !== item._id && item?.discount) ||
            (!valid && item?.discount) ? (
                <div
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        height: 'auto',
                    }}
                >
                    <div
                        style={{
                            height: '15px',
                            fontSize: '10px',
                            lineHeight: '2px',
                            padding: '5px',
                            background: '#7B91F7',
                            color: 'white',
                            borderRadius: '10px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: 'max-content',
                        }}
                    >
                        {item._id === pricePlan()?._id &&
                        couponDetails?.daysChanged ? (
                            `+${
                                couponDetails.finalDays - item.numberOfDays
                            } day${
                                couponDetails.finalDays - item.numberOfDays > 1
                                    ? 's'
                                    : ''
                            } extra`
                        ) : couponDetails?.priceChanged ? (
                            `₹${Math.round(
                                item.price - couponDetails.finalPrice
                            )} off`
                        ) : item?.discount ? (
                            item.discount.discountUnit === 'AMOUNT' ? (
                                `₹${item.discount.discountValue} off`
                            ) : item.discount.discountUnit === 'PERCENTAGE' ? (
                                `${item.discount.discountValue}% off`
                            ) : (
                                `+${item.discount.discountValue} day${
                                    item.discount.discountValue > 1 ? 's' : ''
                                } extra`
                            )
                        ) : (
                            <></>
                        )}
                    </div>
                </div>
            ) : (
                <></>
            )}

            {(valid &&
                item._id === pricePlan()._id &&
                couponDetails.priceChanged) ||
            (valid &&
                item._id !== pricePlan()._id &&
                item?.discount &&
                item.discount.discountUnit !== 'EXTRA_DAYS') ||
            (!valid &&
                item?.discount &&
                item.discount.discountUnit !== 'EXTRA_DAYS') ? (
                <>
                    <Typography.Text delete={true}>
                        ₹ {item.price}
                    </Typography.Text>
                    <br />
                </>
            ) : (
                <></>
            )}

            <Typography.Text
                strong
                style={{
                    fontFamily: 'Plus Jakarta Sans',
                    fontWeight: '700',
                    fontSize: '18px',
                    color: '#FFB800',
                }}
            >
                {valid && pricePlan()._id === item._id
                    ? couponDetails.priceChanged
                        ? `₹ ${Math.round(couponDetails.finalPrice)}`
                        : `₹ ${item.price}`
                    : valid && pricePlan()._id !== item._id && item?.discount
                    ? item.discount.discountUnit === 'AMOUNT'
                        ? `₹ ${Math.round(
                              item.price - item.discount.discountValue
                          )}`
                        : item.discount.discountUnit === 'PERCENTAGE'
                        ? `₹ ${Math.round(
                              (item.price *
                                  (100 - item.discount.discountValue)) /
                                  100
                          )}`
                        : `₹ ${item.price}`
                    : !valid && item?.discount
                    ? item.discount.discountUnit === 'AMOUNT'
                        ? `₹ ${Math.round(
                              item.price - item.discount.discountValue
                          )}`
                        : item.discount.discountUnit === 'PERCENTAGE'
                        ? `₹ ${Math.round(
                              (item.price *
                                  (100 - item.discount.discountValue)) /
                                  100
                          )}`
                        : `₹ ${item.price}`
                    : `₹ ${item.price}`}
            </Typography.Text>
        </>
    )
}

export default DiscountAndCoupon
