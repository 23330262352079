import React from 'react'
import { useSelector } from 'react-redux'
import { Menu } from 'antd'
import { Link } from 'react-router-dom'
import jQuery from 'jquery'
import './HorizontalNav.css'

const SubMenu = Menu.SubMenu

const HorizontalNav = () => {
    const pathname = useSelector(({ common }) => common.pathname)
    const status = useSelector((state) => state.profile.status)
    const getLinks = () => {
        // This creates a JSX element for every name in the list.
        const nameArr = [
            // { name: 'Dashboard', url: 'dashboard', icon: 'icon-dasbhoard' },
            { name: 'Channels', url: 'channels', icon: 'icon-chat' },
            { name: 'Plans & Pricing', url: 'plans', icon: 'icon-card' },
            { name: 'Members', url: 'members', icon: 'icon-contacts' },
            { name: 'Access Codes', url: 'accessCodes', icon: 'icon-family' },
            {
                name: 'Secret Messages',
                url: 'secretMessages',
                icon: 'icon-family',
            },
            // { name: 'Reminders', url: 'reminders', icon: 'icon-forward' },
            { name: 'Discounts', url: 'discounts', icon: 'icon-family' },
            // { name: 'Earnings', url: 'earnings', icon: 'icon-card' },
        ]
        if (status === 'PENDING')
            return (
                <SubMenu
                    className="gx-menu-horizontal gx-submenu-popup-curve gx-inside-submenu-popup-curve"
                    key={'Setup'}
                    title={<span>{'Onboarding Form'}</span>}
                ></SubMenu>
            )
        return nameArr.map((name) => (
            <SubMenu
                className="gx-menu-horizontal gx-submenu-popup-curve gx-inside-submenu-popup-curve"
                key={name.url}
                title={
                    <Link
                        to={`/${name.url}`}
                        className="nav-bar-component-on-select-css"
                    >
                        <span>{name.name}</span>
                    </Link>
                }
            ></SubMenu>
        ))
    }
    return (
        <div
            style={{
                minWidth:
                    jQuery(window).width() * 0.65 < 1000
                        ? jQuery(window).width() * 0.65
                        : 1000,
            }}
        >
            <Menu
                selectedKeys={[pathname.split('/')[1]]}
                mode="horizontal"
                theme="dark"
            >
                {getLinks()}
                {/* <SubMenu
                className={getNavStyleSubMenuClass(navStyle)}
                key="main"
                title={<IntlMessages id="sidebar.main" />}
            >
                <Menu.Item key="sample">
                    <Link to="/sample">
                        <i className="icon icon-widgets" />
                        <IntlMessages id="sidebar.samplePage" />
                    </Link>
                </Menu.Item>
            </SubMenu> */}
            </Menu>
        </div>
    )
}

HorizontalNav.propTypes = {}

export default HorizontalNav
