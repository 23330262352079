import { Box } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { SessionLogin } from './SessionStart'
import { Card, Row, Col, Steps, Divider, Button, Layout } from 'antd'
import { AddChannel } from './AddChannel'
import { PlansCreation } from './PlanCreation'
import AppNotificationContainer from '../../components/AppNotificationContainer'
import { ContactUs } from './ContactUs'
import { useProvideAuth } from '../../authentication/auth-methods/jwt-auth'
import { useSelector } from 'react-redux'
import InsideHeader from '../../containers/Topbar/InsideHeader'
import { BotCreation } from './BotCreation'
import { clearLocalCache } from './SubmitHelper'
// import AppSidebar from '../../containers/App/AppSidebar'

const Step = Steps.Step

const { Content } = Layout

const OnboardingPage = () => {
    const [isLoading, setLoading] = useState(false)
    const { userSignOut } = useProvideAuth()
    const influencerName = useSelector((state) => state.profile.influencerName)
    const onboardingState = useSelector(
        (state) => state.profile.onboardingStatus
    )
    const [step, setStep] = useState(onboardingState === 'PLANS_ADDED' ? 4 : 0)
    // const { navStyle } = useSelector(({ settings }) => settings)
    const setProgress = (val) => {
        setStep(val)
    }
    const logout = () => {
        userSignOut(() => {
            window.location.reload()
        })
    }
    const statusCheck = async () => {
        setLoading(true)
        // console.log(onboardingState)
        if (
            localStorage.getItem('setupDone') ||
            onboardingState === 'PLANS_ADDED'
        ) {
            setStep(4)
        }
        setLoading(false)
    }

    useEffect(() => {
        if (localStorage.getItem('influencerName') !== influencerName) {
            clearLocalCache()
            localStorage.setItem('influencerName', influencerName)
        }
        statusCheck()
        return () => {
            setLoading(false)
        }
    }, [])

    const returnDescription = (val) => {
        switch (val) {
            case 'createSession':
                return (
                    <>
                        <br />
                        <p style={{ color: '#888b8f' }}>
                            Allows fetching channels and bot creation
                        </p>
                        <br />
                    </>
                )
            case 'createBot':
                return (
                    <>
                        <br />
                        <p style={{ color: '#888b8f' }}>
                            Select name and username for your bot
                        </p>
                        <br />
                    </>
                )
            case 'selectChannels':
                return (
                    <>
                        <br />
                        <p style={{ color: '#888b8f' }}>
                            Confirm your free and paid channels
                        </p>
                        <br />
                    </>
                )
            case 'addPlans':
                return (
                    <>
                        <br />
                        <p style={{ color: '#888b8f' }}>
                            Create your paid plans and connect them to channels
                        </p>
                        <br />
                    </>
                )
            case 'addMembers':
                return (
                    <>
                        <br />
                        <p style={{ color: '#888b8f' }}>
                            This is to help manage your existing paid members
                        </p>
                        <br />
                    </>
                )
        }
        return <></>
    }

    return (
        <Layout className="gx-app-layout">
            {/* <AppSidebar navStyle={navStyle} /> */}
            <Layout>
                {<InsideHeader />}
                <Content
                    className={`gx-layout-content ${'gx-container-wrap'} `}
                >
                    <>
                        <div
                            style={{
                                marginTop: '2%',
                                maxWidth: '90%',
                                marginLeft: '5%',
                                maxHeight: '100%',
                                minHeight: '550px',
                            }}
                        >
                            <Card className="gx-card" title="Onboarding">
                                <Row>
                                    <Col sm={6}>
                                        <Steps
                                            direction="vertical"
                                            current={step}
                                        >
                                            <Step
                                                title="Create Session"
                                                description={returnDescription(
                                                    'createSession'
                                                )}
                                            />
                                            <Step
                                                title="Set your bot name"
                                                description={returnDescription(
                                                    'createBot'
                                                )}
                                            />
                                            <Step
                                                title="Select Channels/ Groups "
                                                description={returnDescription(
                                                    'selectChannels'
                                                )}
                                            />
                                            <Step
                                                title="Setup your paid plans"
                                                description={returnDescription(
                                                    'addPlans'
                                                )}
                                            />
                                            <Step
                                                title="Assign Plans to your members"
                                                description={returnDescription(
                                                    'addMembers'
                                                )}
                                            />
                                        </Steps>
                                    </Col>
                                    <Col xs={1}>
                                        <Divider
                                            type="vertical"
                                            style={{
                                                minHeight: '550px',
                                            }}
                                        />
                                    </Col>

                                    {isLoading ? (
                                        <AppNotificationContainer
                                            loading={isLoading}
                                        />
                                    ) : (
                                        <Col lg={16} sm={10}>
                                            {step < 2 ? (
                                                <Box
                                                    sx={{
                                                        marginTop: 8,
                                                        width: '90%',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    {step === 1 ? (
                                                        <BotCreation
                                                            setProgress={
                                                                setProgress
                                                            }
                                                            step={step}
                                                        />
                                                    ) : (
                                                        <SessionLogin
                                                            setProgress={
                                                                setProgress
                                                            }
                                                            step={step}
                                                        />
                                                    )}
                                                </Box>
                                            ) : (
                                                <></>
                                            )}

                                            {step === 2 ? (
                                                <AddChannel
                                                    setProgress={setProgress}
                                                />
                                            ) : (
                                                <></>
                                            )}
                                            {step === 3 ? (
                                                <PlansCreation
                                                    setProgress={setProgress}
                                                />
                                            ) : (
                                                <></>
                                            )}
                                            {step === 4 ? <ContactUs /> : <></>}
                                        </Col>
                                    )}
                                </Row>
                            </Card>

                            <div
                                style={{
                                    marginBottom: 25,
                                    marginLeft: 'auto',
                                    maxWidth: '15%',
                                }}
                            >
                                <Button onClick={logout}>Log out</Button>
                            </div>
                        </div>
                    </>
                </Content>
            </Layout>
        </Layout>
    )
}

export default OnboardingPage
