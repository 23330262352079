import React, { useState } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

// minified version is also included
// import 'react-toastify/dist/ReactToastify.min.css';

export const toastMessageToUser = (message) => {
    localStorage.setItem('userError', message)
    window.dispatchEvent(new Event('userError'))
}

const Toast = () => {
    const [toastOnCooldown, setToastOnCooldown] = useState(false)
    // const notifySuccess = () =>
    //     toast.success('Wow so easy!', {
    //         position: 'top-right',
    //         autoClose: 5000,
    //         hideProgressBar: false,
    //         closeOnClick: true,
    //         pauseOnHover: true,
    //         draggable: true,
    //     })

    const notifyUser = () => {
        const error = localStorage.getItem('userError')
        if (error && !toastOnCooldown) {
            setToastOnCooldown(true)
            setTimeout(() => {
                setToastOnCooldown(false)
            }, 2000)
            toast.error(error, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                toastId: '007',
            })
            localStorage.removeItem('userError')
        }
    }

    const notifyWarn = () => {
        const error = JSON.parse(localStorage.getItem('toastError'))
        if (error?.message && !toastOnCooldown) {
            setToastOnCooldown(true)
            setTimeout(() => {
                setToastOnCooldown(false)
            }, 2000)
            toast.warn(`${error.message} when trying ${error.config.url}`, {
                position: 'top-right',
                autoClose: 9000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                toastId: '000',
            })
            localStorage.removeItem('toastError')
        }
    }

    window.addEventListener('error', () => {
        setTimeout(() => {
            notifyWarn()
        }, 200)
    })

    window.addEventListener('userError', () => {
        setTimeout(() => {
            notifyUser()
        }, 200)
    })

    return (
        <div>
            <ToastContainer />
        </div>
    )
}

export default Toast
