import { axiosClient } from '../../util/Api'
import {
    DASHBOARD_CREATE_PLANS,
    INFLUENCER_ADD_BOT_TO_CHAT,
    INFLUENCER_FETCH_BOTS,
    INFLUENCER_ONBOARDING_STATE_UPDATE,
} from '../../util/ApiUrls'

const transformPlansJson = (plan, botId) => {
    let transformed = []
    plan.forEach((item) => {
        let subPlans = []
        let benefits = []
        item.subPlans.forEach((subItem) => {
            subPlans = [
                ...subPlans,
                {
                    price: Number(subItem.price),
                    numberOfDays: Number(subItem.duration),
                },
            ]
        })
        item.benefits.forEach((subItem) => {
            if (subItem.value.length > 0) {
                benefits = [...benefits, subItem.value]
            }
        })
        transformed = [
            ...transformed,
            {
                name: item.name,
                botId: botId,
                chatIds: item.channelId,
                detail: benefits,
                plans: [...subPlans],
                deactivatedPlans: [],
            },
        ]
    })
    return transformed
}

export const planValidator = (plans, onboarding = false) => {
    let error = 'noSubPlan'
    if (onboarding === true) {
        error = ''
    }
    plans.forEach((element) => {
        if ((element.name === '' || element.name.length < 4) && error === '')
            error = 'name'
        if (element.channelId.length === 0 && error === '') error = 'channel'
        element.subPlans.forEach((item) => {
            if (
                error === 'noSubPlan' &&
                (item.state === 'active' || item.state === 'new')
            ) {
                error = ''
            }
            if (item.state !== 'deactivated') {
                if (Number(item.price) ? false : true && error === '')
                    error = 'price'
                if (Number(item.duration) ? false : true && error === '')
                    error = 'duration'
            }
        })
    })
    switch (error) {
        case 'name':
            return 'VALIDATION_PLAN_NAME'
        case 'channel':
            return 'VALIDATION_PLAN_CHANNEL'
        case 'price':
            return 'VALIDATION_PLAN_PRICE'
        case 'duration':
            return 'VALIDATION_PLAN_DURATION'
        case 'noSubPlan':
            return 'VALIDATION_SUBPLAN'
        default:
            return 'VALIDATION_PLAN_SUCCESS'
    }
}

export const postPlansAndChannels = async (plans) => {
    const func = async (i) => {
        try {
            const data = await axiosClient.get(INFLUENCER_FETCH_BOTS)
            if (data.data.length < 1) {
                setTimeout(() => {
                    func(i + 1)
                }, 5000)
            } else if (i === 5) {
                // handle error somehow, although it should only have an error if server crashes
            } else {
                localStorage.setItem('setupDone', 'true')
                const botUsername = data.data[0].username
                const allChannels = JSON.parse(
                    localStorage.getItem('channelList')
                )
                const linkBot = allChannels.filter(
                    (item) => item.addBot === 'Yes'
                )
                let temp = []
                linkBot.forEach((item) => {
                    temp = [
                        ...temp,
                        {
                            chatId: item.key,
                            chatType: item.type,
                            isPaid: item.membership === 'Paid',
                        },
                    ]
                })
                try {
                    await axiosClient.post(INFLUENCER_ADD_BOT_TO_CHAT, {
                        botUsername: botUsername,
                        chats: temp,
                    })
                } catch {
                    localStorage.removeItem('setupDone')
                }
                const transformedPlans = transformPlansJson(
                    plans,
                    data.data[0].id
                )
                transformedPlans.forEach(async (plan) => {
                    try {
                        await axiosClient.post(DASHBOARD_CREATE_PLANS, plan)
                    } catch {
                        localStorage.removeItem('setupDone')
                    }
                })
                if (localStorage.getItem('setupDone'))
                    axiosClient.post(INFLUENCER_ONBOARDING_STATE_UPDATE, {
                        status: 'PENDING',
                        onboardingStatus: 'PLANS_ADDED',
                    })
            }
        } catch {}
    }
    func(0)
}

export const clearLocalCache = () => {
    localStorage.removeItem('channelList')
    localStorage.removeItem('tempPlans')
}
