import { axiosClient, axiosTralkUserClient } from '../util/Api'
import {
    DASHBOARD_FETCH_PLANS,
    TRALK_INFLUENCER_PROFILE,
} from '../util/ApiUrls'

export const usePlansData = () => {
    const getAllPlans = async () => {
        const concatenateChats = (item) => {
            let chats = ''
            item.chats.forEach((chat) => {
                if (chats === '') {
                    chats = chats + chat.title
                } else {
                    chats = chats + '/n' + chat.title
                }
            })
            return chats
        }

        try {
            const data = await axiosClient.get(DASHBOARD_FETCH_PLANS)
            const tempPlans = []
            data.data.forEach((item, index) => {
                tempPlans.push({
                    key: item._id,
                    id: item._id,
                    planName: item.name ? item.name : '-',
                    chats: item.chats ? concatenateChats(item) : [],
                    details: item.detail ? item.detail : '-',
                    pricePlans: item.plans ? item.plans : [], //    obj id , cost, duration
                    chatDetails: item.chats ? item.chats : [],
                })
            })
            // console.log(tempPlans)
            return tempPlans
        } catch (e) {
            console.log('Error fetching plans ' + e)
            return []
        }
    }

    const getAllPlansLandingPage = async (plans = []) => {
        const concatenateChats = (item) => {
            let chats = ''
            item.chats.forEach((chat) => {
                if (chats === '') {
                    chats = chats + chat.title
                } else {
                    chats = chats + '/n' + chat.title
                }
            })
            return chats
        }

        const tempPlans = []
        plans.forEach((item, index) => {
            tempPlans.push({
                key: item._id,
                id: item._id,
                planName: item.name ? item.name : '-',
                chats: item.chats ? concatenateChats(item) : [],
                details: item.detail ? item.detail : '-',
                pricePlans: item.plans ? item.plans : [],
                chatDetails: item.chats ? item.chats : [],
            })
        })
        // console.log(tempPlans)
        return tempPlans
    }

    const getInfluencerDetailsLandingPage = async (username) => {
        try {
            let data = null
            try {
                data = await axiosTralkUserClient.get(
                    TRALK_INFLUENCER_PROFILE + username
                )
            } catch (e) {
                if (e.response.status === 400) {
                    try {
                        data = await axiosTralkUserClient.get(
                            TRALK_INFLUENCER_PROFILE + username + 'Bot'
                        )
                    } catch {}
                } else {
                    console.log(e)
                }
            }
            if (data === null) {
                throw console.error(
                    'Page  info not retrieved, page URL may be incorrect'
                )
            }
            const plans = await getAllPlansLandingPage(data.data.plans)
            const influencer = { ...data.data.influencer }
            if (influencer.about.length === 0) {
                influencer.about =
                    'Great reliable source for your daily stock advices! Be a part of our community and increase your profits!'
            }
            return {
                influencer: influencer,
                plans,
            }
        } catch (e) {
            console.log(e)
        }
    }

    return {
        getAllPlans,
        getAllPlansLandingPage,
        getInfluencerDetailsLandingPage,
    }
}
