import zhCN from 'antd/lib/locale-provider/zh_CN'
import zhMessages from '../locales/zh-Hans.json'

const ZhLan = {
    messages: {
        ...zhMessages,
    },
    antd: zhCN,
    locale: 'zh-Hans-CN',
}
export default ZhLan
