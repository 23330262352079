import React from 'react'
import { CheckCircleOutlined } from '@ant-design/icons'
import jQuery from 'jquery'
import { Button, Typography } from 'antd'
import './PaymentSuccessful.css'

const PaymentSuccessful = ({ telegramJoinLink, statusPending }) => {
    const windowWidth = jQuery(window).width()
    return (
        <>
            <div
                style={{
                    width: windowWidth < 450 ? windowWidth : 450,
                }}
            >
                <div
                    className="overall"
                    style={{
                        width: windowWidth < 450 ? windowWidth : 450,
                    }}
                >
                    <div className="status" style={{ background: '#97c15c' }}>
                        <CheckCircleOutlined
                            style={{
                                color: 'rgba(230,240,215,255)',
                                fontSize: '100px',
                            }}
                        />
                        <p className="greenText">PAYMENT SUCCESSFUL</p>
                    </div>
                    <div className="nonStatus">
                        <p className="thanks">Thanks for buying the plan!</p>
                        <p className="thanks">
                            {telegramJoinLink() === ''
                                ? statusPending()
                                    ? 'Please wait while we are generating your access link.'
                                    : 'Your subscription for the plan has been extended successfully!'
                                : 'Please copy below link or click on continue to go directly'}
                        </p>
                        {telegramJoinLink() === '' ? (
                            <></>
                        ) : (
                            <>
                                <Typography.Link
                                    copyable={true}
                                    onClick={() => {
                                        navigator.clipboard.writeText(
                                            telegramJoinLink()
                                        )
                                    }}
                                >
                                    {telegramJoinLink()}
                                </Typography.Link>
                                <br />
                                <Button
                                    type="default"
                                    style={{
                                        backgroundColor: 'rgba(151,193,92,255)',
                                        borderColor: 'rgba(151,193,92,255)',
                                        color: 'white',
                                        height: 'auto',
                                        width: 'auto',
                                    }}
                                    shape="round"
                                    onClick={() => {
                                        window.location.href =
                                            telegramJoinLink()
                                    }}
                                >
                                    Join Telegram Channels
                                </Button>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    )
}

export default PaymentSuccessful
