import React, { useEffect, useState } from 'react'
import PaymentSuccessful from './PaymentSuccess'
import PaymentUnsuccessful from './PaymentUnsuccessful'
import jQuery from 'jquery'
import { axiosTralkUserClient } from '../../util/Api'
import { TRALK_GET_PAYMENT_STATUS } from '../../util/ApiUrls'
import { useProvideTralkUserAuth } from '../../authenticationTralk/TralkAuthProvider'
import AppNotificationContainer from '../../components/AppNotificationContainer'

const PaymentStatus = () => {
    const windowWidth = jQuery(window).width()
    const { getHeaders, validate } = useProvideTralkUserAuth()
    const linkId = location.pathname.split('paymentStatus/')[1]
        ? location.pathname.split('paymentStatus/')[1]
        : ''
    const [success, setSuccess] = useState(null)
    const [loading, setLoading] = useState(true)
    const [linkForButton, setLinkForButton] = useState('')
    const [statusPending, setStatusPending] = useState(true)
    const fetcher = async () => {
        axiosTralkUserClient.defaults.headers.common = getHeaders()
        await validate()
        setLoading(true)
        const response = await axiosTralkUserClient.get(
            TRALK_GET_PAYMENT_STATUS + 'paymentLinkId=' + linkId
        )
        if (response.data.status === 'PAID') {
            setSuccess(true)
            if (response.data.statusPending === false) {
                setStatusPending(false)
                setLinkForButton(
                    response.data?.telegramJoinLink
                        ? response.data.telegramJoinLink
                        : ''
                )
            } else {
                setLinkForButton('')
                retryToGetJoinLink()
            }
            setLoading(false)
        } else {
            if (response.data.statusPending === false) {
                setSuccess(false)
                setLinkForButton(response.data.paymentLink)
                setLoading(false)
            } else {
                retryIfPending()
            }
        }
    }

    const retryIfPending = () => {
        const func = async (i) => {
            try {
                const data = await axiosTralkUserClient.get(
                    TRALK_GET_PAYMENT_STATUS + 'paymentLinkId=' + linkId
                )
                if (
                    data.data.statusPending === false ||
                    data.data.status === 'PAID'
                ) {
                    fetcher()
                } else if (i === 20) {
                    setLoading(false)
                    localStorage.setItem(
                        'userError',
                        'Processing taking time, please refresh page after sometime'
                    )
                    window.dispatchEvent(new Event('userError'))
                } else {
                    setTimeout(() => {
                        func(i + 1)
                    }, 2000)
                }
            } catch (e) {}
        }
        func(0)
    }

    const retryToGetJoinLink = () => {
        const func = async (i) => {
            try {
                const data = await axiosTralkUserClient.get(
                    TRALK_GET_PAYMENT_STATUS + 'paymentLinkId=' + linkId
                )
                if (!data.data.statusPending || i === 30) {
                    setStatusPending(false)
                    setLinkForButton(
                        data.data?.telegramJoinLink
                            ? data.data.telegramJoinLink
                            : ''
                    )
                } else {
                    setTimeout(() => {
                        func(i + 1)
                    }, 2000)
                }
            } catch (e) {
                console.log(e)
            }
        }
        func(0)
    }

    useEffect(() => {
        fetcher()
        console.log('inside payment status')
    }, [])
    return (
        <>
            {loading ? (
                <AppNotificationContainer loading={true} />
            ) : (
                <div
                    style={{
                        paddingTop: '3px',
                        marginBottom: '25px',
                        height: '500px',
                        background:
                            'linear-gradient(270deg, #A859D0 -50%, #505FD3 110%)',
                        width: windowWidth < 450 ? windowWidth : 450,
                        marginLeft:
                            windowWidth < 450 ? 0 : (windowWidth - 450) / 2,
                    }}
                >
                    {success ? (
                        <PaymentSuccessful
                            telegramJoinLink={() => linkForButton}
                            statusPending={() => statusPending}
                        />
                    ) : (
                        <PaymentUnsuccessful
                            retryPaymentLink={() => linkForButton}
                        />
                    )}
                </div>
            )}
        </>
    )
}

export default PaymentStatus
