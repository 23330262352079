import { Typography, Button, Input, Space, Row, Col } from 'antd'
import React, { useEffect, useState } from 'react'
import AppNotificationContainer from '../../components/AppNotificationContainer'
import { axiosClient } from '../../util/Api'
import {
    INFLUENCER_CHECK_BOT_AVAILABILITY,
    INFLUENCER_CREATE_BOT,
    INFLUENCER_FETCH_BOTS,
    INFLUENCER_ONBOARDING_STATE_UPDATE,
    INFLUENCER_TELEGRAM_SESSION_CHECK,
    INFLUENCER_BOT_CREATION_STATUS,
} from '../../util/ApiUrls'
import { AlertMessages } from './AlertMessages'
import { toastMessageToUser } from '../../util/Toast'

export const BotCreation = ({ props, setProgress }) => {
    const [botName, setBotName] = useState('')
    const [botUserName, setBotUserName] = useState('')
    const [message, setMessage] = useState('')
    const [isLoading, setLoading] = useState(false)

    const checkUsername = async () => {
        if (
            !/^[a-zA-Z0-9_]*$/.test(botUserName) ||
            !/^[a-zA-Z0-9_]*$/.test(botName)
        ) {
            setMessage('BOTNAME_INVALID')
            return
        }
        try {
            const response = await axiosClient.get(
                INFLUENCER_CHECK_BOT_AVAILABILITY + botUserName + '_bot'
            )
            const responseStatus = response.data.message.split(' ')

            if (response.data.success) {
                setMessage('USERNAME AVAILABLE')
                return true
            } else if (responseStatus[3] === 'to') {
                setMessage('END_WITH_BOT')
            } else {
                setMessage('USERNAME UNAVAILABLE')
            }
            if (botName === '') {
                setMessage('BOTNAME_REQUIRED')
            }
        } catch {
            setMessage('ERROR')
        }
        return false
    }

    const botCheck = async () => {
        setLoading(true)
        let tempStep = 1
        try {
            const data = await axiosClient.get(INFLUENCER_FETCH_BOTS)
            // console.log(data)
            if (data.data?.length > 0) tempStep = 2
        } catch {}
        try {
            const session = await axiosClient.get(
                INFLUENCER_TELEGRAM_SESSION_CHECK
            )
            if (session.data === false) {
                tempStep = 0
            }
        } catch (e) {
            toastMessageToUser(
                'Something went wrong with session creation. Please retry.'
            )
            tempStep = 0
        }
        setLoading(false)
        setProgress(tempStep)
    }

    const createBot = async () => {
        setLoading(true)
        const available = await checkUsername()
        if (available) {
            try {
                await axiosClient.post(INFLUENCER_CREATE_BOT, {
                    botUsername: botUserName + '_bot',
                    botName: botName,
                })
                checkCreation()
                // setProgress(2)
                // setBotCreated(true)
            } catch {}
        } else {
            setLoading(false)
        }
    }
    useEffect(() => {
        console.log('insideBot')
        botCheck()
    }, [])

    const checkCreation = () => {
        setMessage('CREATING_BOT')
        const func = async (i) => {
            try {
                const data = await axiosClient.get(
                    `${INFLUENCER_BOT_CREATION_STATUS}?botUsername=${botUserName}_bot`
                )
                if (data.data) {
                    await axiosClient.post(INFLUENCER_ONBOARDING_STATE_UPDATE, {
                        status: 'PENDING',
                        onboardingStatus: 'BOT_CREATED',
                    })
                    setLoading(false)
                    setProgress(2)
                } else if (i === 24) {
                    setMessage('TIME_EXCEEDED')
                } else {
                    setTimeout(() => {
                        func(i + 1)
                    }, 5000)
                }
            } catch {
                setLoading(false)
                setMessage('ERROR')
            }
        }
        func(0)
    }

    return (
        <>
            <br />

            <Row gutter={[8, 8]}>
                <Col span={8}>
                    <div style={{ marginTop: 5 }} />
                    <div>
                        <Typography.Text strong>Bot Name</Typography.Text>
                    </div>
                    <div style={{ marginTop: 40 }} />
                    <div>
                        <Typography.Text strong>Bot Username</Typography.Text>
                    </div>
                </Col>
                <Col span={12}>
                    <div>
                        <Input
                            style={{ width: 230 }}
                            value={botName}
                            onChange={(e) => {
                                setBotUserName(e.target.value)
                                setBotName(e.target.value)
                            }}
                        />
                    </div>
                    <br />
                    <div>
                        <Input
                            style={{ width: 230 }}
                            value={botUserName}
                            addonAfter="_bot"
                            onChange={(e) => setBotUserName(e.target.value)}
                        />
                    </div>
                </Col>
            </Row>
            <br />
            <br />
            <Space>
                {/* <Button onClick={() => checkUsername()}>Check Username</Button> */}
                <Button type="primary" onClick={createBot}>
                    Confirm Details
                </Button>
            </Space>
            {/* <Button onClick={() => handleEdit()}>Back to Phone Number</Button> */}
            <AlertMessages MESSAGE={message} createBot={createBot} />
            <AppNotificationContainer loading={isLoading} />
        </>
    )
}
