const initialState = {
    members: [
        {
            name: 'Dummy 1',
            username: 'dummy -+ 1',
            joined: 'yesterday',
            channel: 'stock 101',
            membership: 'Paid',
            expiry: 'tomorrow',
            key: 1,
        },
    ],
    pagination: {
        current: 3,
        pageSize: 10,
        total: 10,
        position: 'bottom',
    },
    filters: {
        name: null,
        username: null,
        channel: null,
        membership: null,
    },
    sorter: {},
}

const MembersReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ADD_MEMBER': {
            return {
                ...state,
                members: [...state.members, action.payload],
            }
        }
        case 'CLEAR_MEMBERS': {
            return {
                ...state,
                members: [],
            }
        }
        case 'SET_QUERY_PARAMS': {
            return {
                ...state,
                pagination: action.payload.pagination,
                filters: action.payload.filters,
                sorter: action.payload.sorter,
            }
        }
        case 'CLEAR_QUERY_PARAMS': {
            return {
                ...state,
                pagination: {
                    current: 1,
                    pageSize: 10,
                    position: 'bottom',
                },
                filters: {
                    name: null,
                    username: null,
                    channel: null,
                    membership: null,
                },
                sorter: {},
            }
        }
        default:
            return state
    }
}

export default MembersReducer
