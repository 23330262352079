import React from 'react'
import { CloseCircleOutlined } from '@ant-design/icons'
import jQuery from 'jquery'
import { Button } from 'antd'
import './PaymentUnsuccessful.css'
const PaymentUnsuccessful = ({ retryPaymentLink }) => {
    return (
        <>
            <div
                style={{
                    width:
                        jQuery(window).width() < 450
                            ? jQuery(window).width()
                            : 450,
                }}
            >
                <div
                    className="overall"
                    style={{
                        width:
                            jQuery(window).width() < 450
                                ? jQuery(window).width()
                                : 450,
                    }}
                >
                    <div className="status">
                        <CloseCircleOutlined
                            style={{
                                color: 'rgba(240,230,215,255)',
                                fontSize: '100px',
                            }}
                        />
                        <p className="redText">PAYMENT UNSUCCESSFUL</p>
                    </div>
                    <div className="nonStatus">
                        <div className="gx-text-center">
                            <p className="thanks">
                                Dont worry, your money will be credited back in
                                3-5 working days if deducted.
                            </p>
                            <p className="thanks">
                                {' '}
                                You can retry again to complete your purchase.
                            </p>
                        </div>
                        <Button
                            type="default"
                            onClick={() => {
                                window.location.href = retryPaymentLink()
                            }}
                            style={{
                                backgroundColor: 'rgba(204,96,68,255)',
                                borderColor: 'rgba(204,96,68,255)',
                                color: 'white',
                                height: 'auto',
                                width: 'auto',
                            }}
                            shape="round"
                        >
                            Retry
                        </Button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PaymentUnsuccessful
