import { Space, Typography, Button } from 'antd'
import React from 'react'
import { axiosClient } from '../../util/Api'
import { INFLUENCER_ONBOARDING_STATE_UPDATE } from '../../util/ApiUrls'

export const ContactUs = () => {
    const activateDashboard = async () => {
        await axiosClient.post(INFLUENCER_ONBOARDING_STATE_UPDATE, {
            status: 'ACTIVE',
            onboardingStatus: 'COMPLETED',
        })
        window.location.reload()
    }
    return (
        <>
            <Space direction="vertical" align="center" style={{ width: '80%' }}>
                <br />
                <br />
                <Typography.Text level={3} style={{ fontSize: '20px' }}>
                    Our team will reach out to you to help add your
                </Typography.Text>
                <Typography.Text level={3} style={{ fontSize: '20px' }}>
                    existing members and their subscriptions
                </Typography.Text>
                <br />
                <Typography.Text level={3} style={{ fontSize: '20px' }}>
                    You can also reach out to us at
                </Typography.Text>

                <Typography.Text
                    copyable={{ text: 'influencer@itribe.in' }}
                    level={3}
                    style={{ fontSize: '20px' }}
                >
                    influencer@itribe.in
                </Typography.Text>
                <br />
                <Button
                    onClick={() => {
                        activateDashboard()
                    }}
                    type="primary"
                >
                    Go to dashboard
                </Button>
            </Space>
        </>
    )
}
