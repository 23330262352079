import { Button, Radio, Table, Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import { axiosClient } from '../../util/Api'
import { INFLUENCER_FETCH_TELEGRAM_ADMIN_CHATS } from '../../util/ApiUrls'

const writeLocal = (arr) => {
    localStorage.removeItem('channelList')
    let tempArr = []
    let unselected = []
    arr.forEach((item) => {
        if (item.addBot === 'Yes') {
            tempArr = [...tempArr, item]
        } else {
            unselected = [...unselected, item]
        }
    })
    localStorage.setItem('unselectedChannelList', JSON.stringify(unselected))
    localStorage.setItem('channelList', JSON.stringify(tempArr))
}

export const AddChannel = ({ props, setProgress }) => {
    const tableProps = {
        pagination: { position: 'bottom', defaultPageSize: 5 },
        size: 'default',
    }
    const [tableLoading, setTableLoading] = useState(true)
    const [rows, setRows] = useState([])
    const [rowKeys, setRowKeys] = useState([])
    const columns = [
        {
            title: 'Channel/Group Name',
            dataIndex: 'name',
            width: 120,
            sorter: (a, b) => a.name.localeCompare(b.name),
        },
        {
            title: 'Select Membership',
            dataIndex: 'membership',
            width: 100,
            editable: true,
            render: (membership, record, index) => {
                let editable = false
                if (record.addBot === 'Yes') editable = true
                return (
                    <Radio.Group
                        size="medium"
                        disabled={!editable}
                        value={membership}
                        onChange={(e) =>
                            inputChange(
                                e.target.value,
                                'membership',
                                record.key
                            )
                        }
                    >
                        <Radio.Button value="Paid">Paid</Radio.Button>
                        <Radio.Button value="Free">Free</Radio.Button>
                    </Radio.Group>
                )
            },
        },
    ]

    const inputChange = (val, field, index) => {
        const newData = [...rows]
        for (const item of newData) {
            if (item.key === index) {
                item[field] = val
            }
        }
        writeLocal(newData)
        setRows(newData)
    }

    const getChannels = async () => {
        let savedChannels = localStorage.getItem('channelList')
            ? JSON.parse(localStorage.getItem('channelList'))
            : []

        savedChannels = localStorage.getItem('unselectedChannelList')
            ? [
                  ...savedChannels,
                  ...JSON.parse(localStorage.getItem('unselectedChannelList')),
              ]
            : [...savedChannels]
        try {
            const data = await axiosClient.get(
                INFLUENCER_FETCH_TELEGRAM_ADMIN_CHATS
            )
            let tempRows = []
            data.data.chats.forEach((e) => {
                const exists = savedChannels.filter((item) => item.key === e.id)
                tempRows = [
                    ...tempRows,
                    {
                        name: e.title,
                        membership:
                            exists.length > 0 ? exists[0].membership : 'Free',
                        addBot: exists.length > 0 ? exists[0].addBot : 'Yes',
                        key: e.id,
                        type: e.type,
                    },
                ]
            })
            writeLocal(tempRows)
            setRowKeys(
                tempRows
                    .filter((item) => item.addBot === 'Yes')
                    .map((i) => {
                        return i.key
                    })
            )
            setTableLoading(false)
            setRows(tempRows)
        } catch (e) {
            console.log(e)
            console.log('error getting channels')
        }
    }

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            const tempRows = [...rows]
            tempRows.forEach((item) => {
                item.addBot = 'No'
                selectedRows.forEach((selected) => {
                    if (selected.key === item.key) {
                        item.addBot = 'Yes'
                    }
                })
            })
            setRows(tempRows)
            setRowKeys(selectedRowKeys)
            writeLocal(tempRows)
        },
    }

    useEffect(() => {
        setTableLoading(true)
        getChannels()
        console.log('insideSetupChannels')
        return () => {
            setRows([])
            setTableLoading(false)
        }
    }, [])

    return (
        <>
            <div
                style={{
                    minHeight: '550px',
                }}
            >
                <div
                    style={{
                        height: '550px',
                    }}
                >
                    <Typography.Paragraph strong>
                        Select chats for which bot will be added and mark the
                        membership type
                    </Typography.Paragraph>
                    <Typography>
                        *Adding bot allows generation of user analytics and
                        managing reminders/payments of members.
                    </Typography>
                    <br />
                    <Table
                        rowSelection={{
                            selectedRowKeys: rowKeys,
                            type: 'checkbox',
                            ...rowSelection,
                        }}
                        {...tableProps}
                        loading={tableLoading}
                        columns={columns}
                        dataSource={rows}
                        scroll={{ x: 600, y: 800 }}
                    />
                </div>
                <div style={{ height: 52 }} />
                <div
                    style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                    }}
                >
                    <Button
                        type="primary"
                        onClick={() => {
                            setProgress(3)
                        }}
                    >
                        Next
                    </Button>
                </div>
                {/* <div style={{ display: 'flex' }}>
                    <Button
                        style={{ marginLeft: 'auto', marginBottom: 5 }}
                        type="primary"
                        onClick={() => {
                            setProgress(3)
                        }}
                    >
                        Next
                    </Button>
                </div> */}
            </div>
        </>
    )
}
