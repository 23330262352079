import { axiosClient } from '../util/Api'
import qs from 'qs'
import {
    DASHBOARD_CREATE_SECRET_MESSAGE,
    DASHBOARD_DEACTIVATE_SECRET_MESSAGE,
    DASHBOARD_ACTIVATE_SECRET_MESSAGE,
    DASHBOARD_FETCH_SECRET_MESSAGES,
    DASHBOARD_SEND_SECRET_MESSAGE,
    INFLUENCER_FETCH_BOTS,
} from '../util/ApiUrls'

export const useSecretMessagesData = () => {
    const getAllMessages = async (params) => {
        const transParams = params
            .replace('ascend', 'asc')
            .replace('descend', 'dsc')
        try {
            const data = await axiosClient.get(
                DASHBOARD_FETCH_SECRET_MESSAGES + transParams
            )
            const rows = data.data.secretMessages.map((item) => ({
                messageNumber: item.messageNumber + 1,
                key: item._id,
                status: item.status,
                visible: item.visibleString,
                secret: item.secretString,
                payments: item.payments,
                clicks: item.clicks,
                cost: item.cost,
                secretMedia: item.secretMedia,
                visibleMedia: item.visibleMedia,
                expiry: item.expiryDate,
            }))
            return {
                results: rows,
                total: data.data.fullCount.fullCount
                    ? data.data.fullCount.fullCount
                    : 0,
            }
        } catch {
            console.log('Error fetching members')
            return { results: [], total: 0 }
        }
    }

    const paramify = (data) => {
        const searchParams = JSON.parse(
            JSON.stringify(data)
                .replaceAll('[', '')
                .replaceAll(']', '')
                .replaceAll('pageSize', 'perPage')
                .replaceAll('current', 'page')
        )
        Object.keys(searchParams).forEach(
            (k) =>
                !searchParams[k] &&
                searchParams[k] !== undefined &&
                delete searchParams[k]
        )
        return qs.stringify(searchParams)
    }

    const createSecretMessage = async (
        secretMessageDetails,
        chatId,
        send = false
    ) => {
        try {
            secretMessageDetails.cost = Number(secretMessageDetails.cost)
            const bot = await axiosClient.get(INFLUENCER_FETCH_BOTS)
            const data = await axiosClient.post(
                DASHBOARD_CREATE_SECRET_MESSAGE,
                secretMessageDetails
            )
            if (send) {
                axiosClient.post(DASHBOARD_SEND_SECRET_MESSAGE, {
                    messageId: data.data.messageId,
                    chatId: chatId,
                    botId: bot.data[0].id,
                })
            }
        } catch (e) {
            console.log(e)
        }
    }

    const deactivateSecretMessage = async (messageId, activate = false) => {
        try {
            if (activate) {
                await axiosClient.patch(
                    DASHBOARD_ACTIVATE_SECRET_MESSAGE + messageId
                )
            } else {
                await axiosClient.patch(
                    DASHBOARD_DEACTIVATE_SECRET_MESSAGE + messageId
                )
            }
        } catch (e) {
            console.log(e)
        }
    }

    return {
        getAllMessages,
        createSecretMessage,
        paramify,
        deactivateSecretMessage,
    }
}
