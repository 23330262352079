import './getNumberCard.css'
import React from 'react'
import jQuery from 'jquery'
import { Input, Typography } from 'antd'
const GetNumberCard = ({
    setGetNumber,
    setNumber,
    phoneNumber,
    goToPayment,
    codeAvailable,
    codeDetails,
    freeCodeFlag,
}) => {
    return (
        <>
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
            />
            <div
                style={{
                    position: 'absolute',
                    top: 0,
                    borderRadius: '2px',
                    backgroundColor: 'black',
                    opacity: '0.75',
                    boxShadow: 'none',
                    padding: '10px',
                    height: '100%',
                    width:
                        jQuery(window).width() < 450
                            ? jQuery(window).width()
                            : 450,
                }}
                onClick={() => setGetNumber(false)}
            />
            <div
                style={{
                    position:
                        jQuery(window).width() > 450 ? 'relative' : 'fixed',
                    bottom: 0,
                    borderTopRightRadius: '29px',
                    borderTopLeftRadius: '29px',
                    backgroundColor: '#FFFFFF',
                    boxShadow: 'none',
                    padding: '10px',
                    width:
                        jQuery(window).width() < 450
                            ? jQuery(window).width()
                            : 450,
                }}
            >
                <img
                    src="/assets/images/logoDark.png"
                    style={{ width: '45%', marginLeft: '25%' }}
                />
                {!codeAvailable() ? (
                    <>
                        <Typography.Paragraph className="Please">
                            Please share your details for communication
                        </Typography.Paragraph>
                        <Typography.Paragraph className="MobileNumber">
                            Mobile Number
                        </Typography.Paragraph>
                        <Input
                            value={phoneNumber()}
                            prefix="+91"
                            className="input"
                            onChange={(event) => {
                                setNumber(event.target.value)
                            }}
                        />
                        <button className="submit" onClick={goToPayment}>
                            <Typography.Text
                                style={{
                                    color: 'white',
                                    fontFamily: 'Plus Jakarta Sans',
                                }}
                            >
                                Continue
                            </Typography.Text>
                        </button>
                    </>
                ) : (
                    <>
                        {freeCodeFlag() ? (
                            <></>
                        ) : (
                            <Typography.Paragraph className="Please">
                                You already have an unused access code for this
                                Plan.
                            </Typography.Paragraph>
                        )}
                        <Typography.Paragraph className="Please">
                            You can visit this link to use the code and join the
                            telegram channels!
                        </Typography.Paragraph>
                        <Typography.Paragraph copyable={true}>
                            {codeDetails().paymentLink.telegramJoinLink}
                        </Typography.Paragraph>
                        <button
                            className="submit"
                            onClick={() =>
                                (window.location.href =
                                    codeDetails().paymentLink.telegramJoinLink)
                            }
                        >
                            <Typography.Text
                                style={{
                                    color: 'white',
                                    fontFamily: 'Plus Jakarta Sans',
                                }}
                            >
                                Continue to Telegram Channels
                            </Typography.Text>
                        </button>
                    </>
                )}
            </div>
        </>
    )
}

export default GetNumberCard
