export const externalLinkHandler = (link) => {
    const linkStarts = link.split('href="')
    let formattedString = linkStarts[0]
    for (let i = 1; i < linkStarts.length; i++) {
        if (linkStarts[i].includes('http') || linkStarts[i].includes('//')) {
            // just concatenate
            formattedString = formattedString + 'href="' + linkStarts[i]
        } else {
            formattedString = formattedString + 'href="//' + linkStarts[i]
        }
    }
    return formattedString.replaceAll('\n', '<br>')
}
