import antdEN from 'antd/lib/locale-provider/en_US'
import enMessages from '../locales/en_US.json'

const EnLang = {
    messages: {
        ...enMessages,
    },
    antd: antdEN,
    locale: 'en-US',
}
export default EnLang
