import axios from 'axios'
import { axiosClient } from '../util/Api'
import { MEDIA_GET_URL, MEDIA_UPLOAD_URL } from '../util/ApiUrls'

export const useS3Data = () => {
    const uploadImageAntdLayer = async (
        options,
        setProgress,
        setFileKey,
        fileKey,
        fileToKey,
        setFileToKey,
        folder,
        allowPublicRead = false
    ) => {
        const { onSuccess, onError, file, onProgress } = options

        const axiosInstance = axios.create({
            headers: { 'Content-Type': file.type },
            onUploadProgress: (event) => {
                const percent = Math.floor((event.loaded / event.total) * 100)
                setProgress(percent)
                if (percent === 100) {
                    setTimeout(() => setProgress(0), 1000)
                }
                onProgress({ percent: (event.loaded / event.total) * 100 })
            },
        })
        const urlData = await getPutUrl(allowPublicRead, folder, file.type)
        try {
            if (allowPublicRead) {
                setFileKey(urlData.url.split('?')[0])
            } else {
                fileToKey[file.uid] = urlData.fileKey
                setFileToKey(fileToKey)
                setFileKey([...fileKey, urlData.fileKey])
            }
            await axiosInstance.put(urlData.url, file)

            onSuccess('Ok')
        } catch (err) {
            console.log('Error: ', err)
            onError({ err })
        }
    }

    const getPutUrl = async (publicRead, folder, type) => {
        try {
            const data = await axiosClient.get(
                MEDIA_UPLOAD_URL +
                    `allowPublicRead=${publicRead}&type=${type}&path=${folder}`
            )
            return data.data
        } catch (e) {
            console.log(e)
        }
    }

    const getAccessUrl = async (fileKey) => {
        try {
            const data = await axiosClient.get(MEDIA_GET_URL + fileKey)
            return data.data
        } catch (e) {
            console.log(e)
        }
    }

    return {
        getPutUrl,
        getAccessUrl,
        uploadImageAntdLayer,
    }
}
