import { Container } from '@mui/material'
import {
    Card,
    Typography,
    Form,
    Input,
    Button,
    Row,
    Col,
    Modal,
    Image,
} from 'antd'
import React, { useEffect, useState } from 'react'
import { useProvideUserAuth } from '../../authentication/auth-methods/jwt-auth'
import AppNotificationContainer from '../../components/AppNotificationContainer'
import { axiosUserClient } from '../../util/Api'
import jQuery from 'jquery'
import {
    USER_GET_SECRET_MESSAGE_PAYMENT_LINK,
    USER_SECRET_MESSAGE_ACCESS,
} from '../../util/ApiUrls'
import { useHistory } from 'react-router-dom'
import TelegramLoginButton from 'react-telegram-login'
import { useSecretMessagesData } from '../../dataAccess/SecretMessagesData'
import { useS3Data } from '../../dataAccess/MediaS3Data'
import { externalLinkHandler } from './UserSecretsHelper'
import { toastMessageToUser } from '../../util/Toast'

const FormItem = Form.Item

const UserSecretMessagePage = (props) => {
    const history = useHistory()
    const { paramify } = useSecretMessagesData()
    const windowWidth = jQuery(window).width()
    const { getAccessUrl } = useS3Data()
    const { setHeaders, getHeaders } = useProvideUserAuth()
    const [telLogin, setTelLogin] = useState(false)
    const [auth, setAuth] = useState(false)
    const [visible, setVisible] = useState('')
    const [visibleMedia, setVisibleMedia] = useState('')
    const [secret, setSecret] = useState(null)
    const [secretMediaUrls, setSecretMediaUrls] = useState({})
    const [cost, setCost] = useState('')
    const [secretMediaImageModalVisible, setSecretMediaImageModalVisible] =
        useState(false)
    const [imageView, setImageView] = useState('')
    const [expiry, setExpiry] = useState(null)
    const [loadingUrls, setLoadingUrls] = useState(false)
    const [loading, setIsLoading] = useState(false)
    const [retrying, setRetrying] = useState(false)
    const [influencerName, setInfluencerName] = useState('')
    const [influencerPhotoUrl, setInfluencerPhotoUrl] = useState('')
    const authParams = localStorage.getItem('userAuthResponse')
    const [showForm, setShowForm] = useState(false)

    const infoFromUrl = location.pathname.split('ages/')[1]
        ? location.pathname.split('ages/')[1]
        : ''
    const messageDetails = infoFromUrl.split('/')[0]
    const botId = process.env.REACT_APP_TG_BOT_ID
    const doPayment = async (input) => {
        const messageId = messageDetails ? messageDetails.split('/')[0] : ''
        try {
            const getPaymentLinkRes = await axiosUserClient.post(
                'secretMessagePayments/getPaymentLink/',
                {
                    messageId: messageId,
                    customerDetails: {
                        customerEmail: input.email,
                        customerPhone: input.phone,
                    },
                }
            )
            if (getPaymentLinkRes?.data && getPaymentLinkRes.data.length > 0) {
                window.location.replace(getPaymentLinkRes.data)
            } else {
                throw new Error('Empty Payment link')
            }
        } catch (e) {
            console.log(`error: ${e}`)
            toastMessageToUser(
                'Something went wrong in fetching payment link. Please try again later.'
            )
        }
    }
    const dataOnauth = (response) => {
        const paramed = paramify(response)
        window.location.href = `${window.location.href}?${paramed}`.replace(
            'userSecretMessages',
            'api/secretMessage'
        )
    }
    const retryToGetStatus = () => {
        const func = async (i) => {
            try {
                const data = await axiosUserClient.get(
                    USER_SECRET_MESSAGE_ACCESS + messageDetails
                )
                if (i === 5) {
                    history.push('../' + messageDetails)
                    setRetrying(false)
                } else if (data.data.paymentStatus === 'PAID') {
                    getMessage()
                    setRetrying(false)
                } else {
                    setTimeout(() => {
                        func(i + 1)
                    }, 2000)
                }
            } catch (e) {
                console.log(e)
            }
        }
        func(0)
    }

    const urlFetcher = async (secretMedia) => {
        setLoadingUrls(true)
        const urls = { image: [], video: [] }
        for (const item of secretMedia.image) {
            const url = await getAccessUrl(`secretMessage/${item}`)
            urls.image.push(url)
        }
        setSecretMediaUrls(urls)
        setLoadingUrls(false)
    }

    const getMessage = async () => {
        if (props?.secretInfo?.preview) {
            setVisible(externalLinkHandler(props.secretInfo.visibleString))
            setAuth(props.secretInfo.auth)
            setSecret(externalLinkHandler(props.secretInfo.secretString))
            setCost(props.secretInfo.cost)
            setVisibleMedia(props.secretInfo.visibleMedia)
            urlFetcher(props.secretInfo.secretMedia)
            setInfluencerName(props.secretInfo.influencerName)
            setInfluencerPhotoUrl(props.secretInfo.photoUrl)
        } else {
            setIsLoading(true)
            const headers = getHeaders()
            if (
                location.origin.includes('influencers.itribe.in') &&
                !localStorage.getItem('userAuthResponse')
            ) {
                window.location.href = `${location.href.replace(
                    'influencers.itribe.in',
                    'tgf-admin.itribe.in'
                )}`
            }
            if (
                location.origin.includes('tgf-admin.itribe.in') &&
                localStorage.getItem('userAuthResponse')
            ) {
                window.location.href = `${location.href
                    .replace('tgf-admin.itribe.in', 'influencers.itribe.in')
                    .replace(
                        'userSecretMessages',
                        'api/secretMessage'
                    )}${localStorage.getItem('userAuthResponse')}`
            }
            if (headers) {
                axiosUserClient.defaults.headers.common = headers
            }
            try {
                const accessMessage = await axiosUserClient.get(
                    USER_SECRET_MESSAGE_ACCESS + messageDetails
                )
                if (
                    infoFromUrl.split('/').length > 1 &&
                    accessMessage.data.paymentStatus !== 'PAID'
                ) {
                    setRetrying(true)
                    retryToGetStatus()
                }
                setInfluencerPhotoUrl(accessMessage.data.influencerPhotoUrl)
                setInfluencerName(accessMessage.data.influencerName)
                setVisible(
                    externalLinkHandler(accessMessage.data.visibleString)
                )
                setVisibleMedia(accessMessage.data.visibleMedia)
                setExpiry(accessMessage.data.expiryDate)
                if (accessMessage.data.paymentStatus !== 'PAID') {
                    throw Object.assign(new Error('Payment not done'), {
                        code: 401,
                    })
                }
                setSecret(externalLinkHandler(accessMessage.data.secretString))
                urlFetcher(accessMessage.data.secretMedia)
                setAuth(true)
            } catch (e) {
                try {
                    const response = await axiosUserClient.get(
                        USER_GET_SECRET_MESSAGE_PAYMENT_LINK +
                            messageDetails +
                            authParams
                    )
                    setInfluencerPhotoUrl(response.data.influencerPhotoUrl)
                    setInfluencerName(response.data.influencerName)
                    setCost(response.data.cost)
                    setVisibleMedia(response.data.visibleMedia)
                    setExpiry(response.data.expiryDate)
                    setVisible(externalLinkHandler(response.data.visibleString))
                    setHeaders(
                        response.data.accessToken,
                        response.data.refreshToken
                    )
                } catch (e) {
                    console.log(e)
                    if (e.response.status === 401 || !authParams) {
                        setTelLogin(true)
                    } else {
                        toastMessageToUser(
                            'Something went wrong in fetching secret message details.'
                        )
                    }
                }
            }
            setIsLoading(false)
        }
    }

    useEffect(() => {
        getMessage()
    }, [])

    return (
        <>
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
            />
            <Modal
                visible={secretMediaImageModalVisible}
                onOk={() => {
                    setImageView('')
                    setSecretMediaImageModalVisible(false)
                }}
                title="Secret Image"
            ></Modal>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: 0,
                    width: props?.secretInfo?.preview
                        ? 'auto'
                        : windowWidth < 450
                        ? windowWidth - 20
                        : 400,
                    marginLeft: props?.secretInfo?.preview
                        ? ''
                        : windowWidth < 450
                        ? 0
                        : (windowWidth - 450) / 2,
                    height: props?.secretInfo?.preview
                        ? 'auto'
                        : windowWidth < 450
                        ? '100%'
                        : 'auto',
                    overflowY: 'scroll',
                    overflowX: 'hidden',
                    position: 'relative',
                }}
            >
                {!loading && !retrying ? (
                    <Card
                        size="default"
                        style={{
                            backgroundColor: '#fbfbfb',
                        }}
                    >
                        {!props?.secretInfo?.preview && (
                            <div
                                style={{
                                    height:
                                        windowWidth > 450 ? '100px' : '50px',
                                }}
                            />
                        )}
                        <Container maxWidth="lg">
                            <div className="gx-text-center">
                                {auth ? (
                                    <img src="/assets/images/unlocked.png" />
                                ) : (
                                    <img src="/assets/images/locked.png" />
                                )}

                                <Typography.Paragraph
                                    style={{
                                        marginTop: '20px',
                                        fontFamily: 'Plus Jakarta Sans',
                                        fontSize: '20px',
                                        fontWeight: '700',
                                        color: '#0F1622',
                                    }}
                                >
                                    {auth
                                        ? 'Secret Advice Unlocked'
                                        : 'New Secret Advice'}
                                </Typography.Paragraph>
                                {telLogin && (
                                    <TelegramLoginButton
                                        dataOnauth={dataOnauth}
                                        botName={botId}
                                        style={{
                                            width:
                                                windowWidth < 450
                                                    ? windowWidth - 40
                                                    : 380,
                                            marginLeft: 20,
                                        }}
                                    />
                                )}
                            </div>
                            {!telLogin && (
                                <div
                                    style={{
                                        width:
                                            windowWidth < 450
                                                ? windowWidth - 40
                                                : 380,
                                        marginLeft: 20,
                                        backgroundColor: 'white',
                                        boxShadow: '10px',
                                        borderRadius: '20px',
                                        paddingTop: '30px',
                                        paddingBottom: '0px',
                                        paddingLeft: '0px',
                                        paddingRight: '0px',
                                        marginBottom: '20px',
                                    }}
                                >
                                    {showForm ? (
                                        <>
                                            <Form
                                                initialValues={{
                                                    remember: true,
                                                }}
                                                name="payment"
                                                onFinish={doPayment}
                                            >
                                                <div
                                                    style={{
                                                        minHeight: '150px',
                                                        marginLeft: '24px',
                                                    }}
                                                >
                                                    <FormItem
                                                        name="email"
                                                        label="Email"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                type: 'email',
                                                                message:
                                                                    'The input is not valid E-mail!',
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            placeholder="Email"
                                                            style={{
                                                                width: '85%',
                                                            }}
                                                        />
                                                    </FormItem>

                                                    <FormItem
                                                        name="phone"
                                                        label="Phone Number"
                                                        rules={[
                                                            {
                                                                required: true,
                                                                message:
                                                                    'Please input your phone number!',
                                                            },
                                                        ]}
                                                    >
                                                        <Input
                                                            addonBefore="+91"
                                                            style={{
                                                                width: '80%',
                                                            }}
                                                        />
                                                    </FormItem>
                                                </div>
                                                <FormItem>
                                                    <div
                                                        className="gx-text-center"
                                                        style={{
                                                            marginLeft: 16,
                                                            width:
                                                                windowWidth <
                                                                450
                                                                    ? windowWidth -
                                                                      40
                                                                    : 380,
                                                            marginTop: '10px',
                                                            backgroundColor:
                                                                '#FFB800',
                                                            height: '43px',
                                                            borderBottomLeftRadius:
                                                                '20px',
                                                            borderBottomRightRadius:
                                                                '20px',
                                                        }}
                                                    >
                                                        <Button
                                                            htmlType="submit"
                                                            style={{
                                                                backgroundColor:
                                                                    'transparent',
                                                                width: '90%',
                                                                borderColor:
                                                                    'transparent',
                                                            }}
                                                        >
                                                            <Typography.Text
                                                                style={{
                                                                    fontFamily:
                                                                        'Plus Jakarta Sans',
                                                                    fontSize:
                                                                        '14px',
                                                                    fontWeight:
                                                                        '600',
                                                                    color: 'black',
                                                                }}
                                                            >
                                                                Continue
                                                            </Typography.Text>
                                                        </Button>
                                                    </div>
                                                </FormItem>
                                            </Form>
                                        </>
                                    ) : (
                                        <>
                                            <div style={{ minHeight: '150px' }}>
                                                <div
                                                    style={{
                                                        marginLeft: '24px',
                                                    }}
                                                >
                                                    <span>
                                                        <img
                                                            style={{
                                                                width: '32px',
                                                                height: '32x',
                                                                borderRadius:
                                                                    '20px',
                                                            }}
                                                            src={
                                                                influencerPhotoUrl ===
                                                                ''
                                                                    ? '/assets/images/placeholder.jpg'
                                                                    : influencerPhotoUrl
                                                            }
                                                        />
                                                        <Typography.Text
                                                            style={{
                                                                marginLeft:
                                                                    '10px',
                                                                fontFamily:
                                                                    'Plus Jakarta Sans',
                                                                fontWeight:
                                                                    '600',
                                                                color: '#0F1622',
                                                            }}
                                                        >
                                                            {influencerName}
                                                        </Typography.Text>
                                                    </span>
                                                    <div
                                                        style={{
                                                            height: '20px',
                                                        }}
                                                    />
                                                </div>
                                                <Typography.Paragraph
                                                    style={{
                                                        marginLeft: '24px',
                                                        fontFamily:
                                                            'Plus Jakarta Sans',
                                                        fontWeight: '600',
                                                    }}
                                                >
                                                    <div
                                                        dangerouslySetInnerHTML={{
                                                            __html: visible,
                                                        }}
                                                    />
                                                    {visibleMedia !== '' && (
                                                        <img
                                                            src={visibleMedia}
                                                        />
                                                    )}
                                                </Typography.Paragraph>
                                                {auth ? (
                                                    <Typography.Paragraph
                                                        style={{
                                                            marginLeft: '24px',
                                                            fontFamily:
                                                                'Plus Jakarta Sans',
                                                            fontWeight: '600',
                                                        }}
                                                    >
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: secret,
                                                            }}
                                                        />
                                                        {loadingUrls ? (
                                                            <AppNotificationContainer
                                                                loading={
                                                                    loadingUrls
                                                                }
                                                            />
                                                        ) : (
                                                            <Row>
                                                                {secretMediaUrls.image.map(
                                                                    (item) => (
                                                                        <Col
                                                                            span={
                                                                                12
                                                                            }
                                                                            key={
                                                                                item
                                                                            }
                                                                        >
                                                                            <Image
                                                                                src={
                                                                                    item
                                                                                }
                                                                                width="auto"
                                                                                height="auto"
                                                                            />
                                                                        </Col>
                                                                    )
                                                                )}
                                                            </Row>
                                                        )}
                                                    </Typography.Paragraph>
                                                ) : (
                                                    <img
                                                        style={{
                                                            marginLeft: '20px',
                                                        }}
                                                        src="/assets/images/blurredSecret.png"
                                                    />
                                                )}
                                            </div>
                                            {auth ? (
                                                <></>
                                            ) : (
                                                <div
                                                    className="gx-text-center"
                                                    style={{
                                                        marginTop: '10px',
                                                        backgroundColor:
                                                            '#FFB800',
                                                        height: '43px',
                                                        borderBottomLeftRadius:
                                                            '20px',
                                                        borderBottomRightRadius:
                                                            '20px',
                                                    }}
                                                >
                                                    <Button
                                                        onClick={() =>
                                                            setShowForm(true)
                                                        }
                                                        disabled={
                                                            !(
                                                                expiry !==
                                                                    null &&
                                                                new Date(
                                                                    expiry
                                                                ) > new Date()
                                                            )
                                                        }
                                                        style={{
                                                            backgroundColor:
                                                                'transparent',
                                                            width: '90%',
                                                            borderColor:
                                                                'transparent',
                                                        }}
                                                    >
                                                        {expiry !== null &&
                                                        new Date(expiry) >
                                                            new Date() ? (
                                                            <Typography.Text
                                                                style={{
                                                                    fontFamily:
                                                                        'Plus Jakarta Sans',
                                                                    fontSize:
                                                                        '14px',
                                                                    fontWeight:
                                                                        '600',
                                                                    color: 'black',
                                                                }}
                                                            >
                                                                Unlock for Rs.
                                                                {cost}
                                                            </Typography.Text>
                                                        ) : (
                                                            <Typography.Text
                                                                style={{
                                                                    fontFamily:
                                                                        'Plus Jakarta Sans',
                                                                    fontSize:
                                                                        '14px',
                                                                    fontWeight:
                                                                        '600',
                                                                    color: 'black',
                                                                }}
                                                            >
                                                                {props
                                                                    ?.secretInfo
                                                                    ?.preview
                                                                    ? 'Unlock for Rs.XX'
                                                                    : 'Message expired!'}
                                                            </Typography.Text>
                                                        )}
                                                    </Button>
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                            )}
                        </Container>
                    </Card>
                ) : (
                    <AppNotificationContainer loading={loading} />
                )}
            </div>
        </>
    )
}

export default UserSecretMessagePage
