import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    Redirect,
    Route,
    Switch,
    useHistory,
    useLocation,
    useRouteMatch,
} from 'react-router-dom'
import { ConfigProvider } from 'antd'
import { IntlProvider } from 'react-intl'

import AppLocale from '../../lngProvider'
import MainApp from './MainApp'
import SignIn from '../SignIn'
import SignUp from '../SignUp'
import { setInitUrl } from '../../appRedux/actions'

import {
    LAYOUT_TYPE_BOXED,
    LAYOUT_TYPE_FRAMED,
    LAYOUT_TYPE_FULL,
    NAV_STYLE_ABOVE_HEADER,
    NAV_STYLE_BELOW_HEADER,
    NAV_STYLE_DARK_HORIZONTAL,
    NAV_STYLE_DEFAULT_HORIZONTAL,
    NAV_STYLE_INSIDE_HEADER_HORIZONTAL,
    THEME_TYPE_DARK,
} from '../../constants/ThemeSetting'
import CircularProgress from '../../components/CircularProgress'
import { useAuth } from '../../authentication'
import OnboardingPage from '../../routes/OnboardingPage'
import UserSecretMessagePage from '../../routes/UserSecretMessagesPage'
import InfluencerLandingPage from '../../routes/InfluencerLandingPage'
import Toast from '../../util/Toast'
import PaymentStatus from '../../routes/InfluencerLandingPage/PaymentStatus'

const RestrictedRoute = ({
    component: Component,
    location,
    authInfluencer,
    status,
    ...rest
}) => (
    <Route
        {...rest}
        render={(props) =>
            location.pathname.includes('profile') ? (
                <>
                    <Redirect
                        to={{
                            pathname: '/profile',
                            state: { from: location },
                        }}
                    />
                </>
            ) : location.pathname.includes('paymentStatus') ? (
                <>
                    <Redirect
                        to={{
                            pathname: '/paymentStatus',
                            state: { from: location },
                        }}
                    />
                </>
            ) : location.pathname.includes('api') ||
              location.pathname.includes('user') ? (
                <Redirect
                    to={{
                        pathname: '/userSecretMessages',
                        state: { from: location },
                    }}
                />
            ) : authInfluencer ? (
                status === 'PENDING' ? (
                    <Redirect
                        to={{
                            pathname: '/setup',
                            state: { from: location },
                        }}
                    />
                ) : (
                    <Component {...props} />
                )
            ) : (
                <Redirect
                    to={{
                        pathname: '/signin',
                        state: { from: location },
                    }}
                />
            )
        }
    />
)

const setLayoutType = (layoutType) => {
    if (layoutType === LAYOUT_TYPE_FULL) {
        document.body.classList.remove('boxed-layout')
        document.body.classList.remove('framed-layout')
        document.body.classList.add('full-layout')
    } else if (layoutType === LAYOUT_TYPE_BOXED) {
        document.body.classList.remove('full-layout')
        document.body.classList.remove('framed-layout')
        document.body.classList.add('boxed-layout')
    } else if (layoutType === LAYOUT_TYPE_FRAMED) {
        document.body.classList.remove('boxed-layout')
        document.body.classList.remove('full-layout')
        document.body.classList.add('framed-layout')
    }
}

const setNavStyle = (navStyle) => {
    if (
        navStyle === NAV_STYLE_DEFAULT_HORIZONTAL ||
        navStyle === NAV_STYLE_DARK_HORIZONTAL ||
        navStyle === NAV_STYLE_INSIDE_HEADER_HORIZONTAL ||
        navStyle === NAV_STYLE_ABOVE_HEADER ||
        navStyle === NAV_STYLE_BELOW_HEADER
    ) {
        document.body.classList.add('full-scroll')
        document.body.classList.add('horizontal-layout')
    } else {
        document.body.classList.remove('full-scroll')
        document.body.classList.remove('horizontal-layout')
    }
}

const App = () => {
    const locale = useSelector(({ settings }) => settings.locale)
    const navStyle = useSelector(({ settings }) => settings.navStyle)
    const layoutType = useSelector(({ settings }) => settings.layoutType)
    const themeType = useSelector(({ settings }) => settings.themeType)
    const isDirectionRTL = useSelector(
        ({ settings }) => settings.isDirectionRTL
    )
    const initURL = useSelector(({ settings }) => settings.initURL)
    const status = useSelector((state) => state.profile.status)
    const { authInfluencer, isLoadingInfluencer } = useAuth()
    const dispatch = useDispatch()
    const location = useLocation()
    const history = useHistory()
    const match = useRouteMatch()

    useEffect(() => {
        if (isDirectionRTL) {
            document.documentElement.classList.add('rtl')
            document.documentElement.setAttribute('data-direction', 'rtl')
        } else {
            document.documentElement.classList.remove('rtl')
            document.documentElement.setAttribute('data-direction', 'ltr')
        }
    }, [isDirectionRTL])

    useEffect(() => {
        if (locale) document.documentElement.lang = locale.locale
    }, [locale])

    useEffect(() => {
        if (themeType === THEME_TYPE_DARK) {
            document.body.classList.add('dark-theme')
        } else if (document.body.classList.contains('dark-theme')) {
            document.body.classList.remove('dark-theme')
        }
    }, [themeType])

    useEffect(() => {
        if (initURL === '') {
            dispatch(setInitUrl(location.pathname))
        }
    })
    useEffect(() => {
        if (!isLoadingInfluencer) {
            if (
                location.pathname.includes('api') ||
                location.pathname.includes('user') === true
            ) {
                let messageUrl = ''
                if (location.pathname.includes('api')) {
                    messageUrl =
                        '/userSecretMessages/' +
                        location.pathname.split('age/')[1]
                    localStorage.setItem('userAuthResponse', location.search)
                } else {
                    messageUrl = location.pathname
                }
                history.push(messageUrl)
            } else if (location.pathname.includes('profile')) {
                localStorage.setItem(
                    'landingPageQuery',
                    location.search.substring(1)
                )
                history.push(location.pathname)
            } else if (location.pathname.includes('paymentStatus')) {
                history.push(location.pathname)
            } else if (!authInfluencer) {
                history.push('/signin')
            } else if (status === 'PENDING') {
                history.push('/setup')
            } else if (
                initURL === '' ||
                initURL === '/' ||
                initURL === '/signin' ||
                initURL === '/setup'
            ) {
                history.push('/channels')
            } else {
                history.push(initURL)
            }
        }
    }, [isLoadingInfluencer, authInfluencer, initURL, history])

    useEffect(() => {
        setLayoutType(layoutType)
        setNavStyle(navStyle)
    }, [layoutType, navStyle])

    const currentAppLocale = AppLocale[locale.locale]

    return isLoadingInfluencer ? (
        <>
            <Toast />
            <CircularProgress />
        </>
    ) : (
        <>
            <Toast />
            <ConfigProvider
                locale={currentAppLocale.antd}
                direction={isDirectionRTL ? 'rtl' : 'ltr'}
            >
                <IntlProvider
                    locale={currentAppLocale.locale}
                    messages={currentAppLocale.messages}
                >
                    <Switch>
                        <Route exact path="/signin" component={SignIn} />
                        <Route exact path="/signup" component={SignUp} />
                        <Route path="/setup" component={OnboardingPage} />
                        <Route
                            path="/userSecretMessages"
                            component={UserSecretMessagePage}
                        />
                        <Route
                            path="/profile"
                            component={InfluencerLandingPage}
                        />
                        <Route
                            path="/paymentStatus"
                            component={PaymentStatus}
                        />
                        <RestrictedRoute
                            path={`${match.url}`}
                            authInfluencer={authInfluencer}
                            location={location}
                            component={MainApp}
                            status={status}
                        />
                    </Switch>
                </IntlProvider>
            </ConfigProvider>
        </>
    )
}

export default App
