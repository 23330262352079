import {
    Button,
    Col,
    Dropdown,
    Menu,
    Input,
    Row,
    Space,
    Tabs,
    Typography,
    Select,
} from 'antd'
import { DownOutlined } from '@ant-design/icons'
import React, { useEffect, useState } from 'react'
import { planValidator, postPlansAndChannels } from './SubmitHelper'
import { AlertMessages } from './AlertMessages'

const { TabPane } = Tabs

const fetchCached = () => {
    const cache = JSON.parse(localStorage.getItem('tempPlans'))
    if (cache) {
        cache.forEach((item) => {
            item.channelId = []
        })
        return cache
    } else {
        return [
            {
                key: 0,
                name: '',
                benefits: [{ value: '', y: 0 }],
                subPlans: [{ price: '', duration: '', y: 0 }],
                channelId: [],
            },
        ]
    }
}

export const PlansCreation = ({ props, setProgress }) => {
    const [plans, setPlans] = useState([
        {
            key: 0,
            name: '',
            benefits: [{ value: '', y: 0 }],
            subPlans: [{ price: '', duration: '', y: 0 }],
            channelId: [],
        },
    ])
    const [activePane, setactivePane] = useState('0')
    const [channelList, setChannelList] = useState([])
    const [message, setMessage] = useState('')
    const validateEntries = () => {
        const check = planValidator(plans, true)
        setMessage(check)
        if (check === 'VALIDATION_PLAN_SUCCESS') {
            postPlansAndChannels(plans)
            setProgress(4)
        }
    }

    const onPlanChange = (targetKey, type) => {
        switch (type) {
            case 'add': {
                const newPlan = [
                    ...plans,
                    {
                        key: plans.length,
                        name: '',
                        benefits: [{ value: '', y: 0 }],
                        subPlans: [{ price: '', duration: '', y: 0 }],
                        channelId: [],
                    },
                ]

                setPlans(newPlan)
                setactivePane(`${plans.length}`)
                break
            }
            case 'remove': {
                const panes = JSON.parse(JSON.stringify(plans))
                const removedPane = panes.filter(
                    (pane) => pane.key.toString() !== targetKey.toString()
                )
                const newPanes = removedPane.filter((pane) => {
                    if (targetKey < pane.key) {
                        pane.key = pane.key - 1
                    }
                    return true
                })
                setactivePane('0')
                setPlans(newPanes)
                localStorage.removeItem('tempPlans')
                break
            }
        }
        localStorage.setItem('tempPlans', JSON.stringify(plans))
    }

    const onBenefitsChange = (val, type, targetKey, y) => {
        switch (type) {
            case 'edit': {
                const newPlans = [...plans]
                newPlans[targetKey].benefits[y].value = val
                setPlans(newPlans)
                break
            }
            case 'add': {
                const newPlans = [...plans]
                newPlans[targetKey].benefits = [
                    ...newPlans[targetKey].benefits,
                    { value: '', y: newPlans[targetKey].benefits.length },
                ]
                setPlans(newPlans)
                break
            }
            case 'remove': {
                const newPlans = [...plans]
                let temp = []
                newPlans[targetKey].benefits.forEach((element) => {
                    if (element.y > y)
                        temp = [
                            ...temp,
                            {
                                value: element.value,
                                y: element.y - 1,
                            },
                        ]
                    else if (element.y < y)
                        temp = [
                            ...temp,
                            {
                                value: element.value,
                                y: element.y,
                            },
                        ]
                })
                newPlans[targetKey].benefits = temp
                setPlans(newPlans)
                localStorage.removeItem('tempPlans')
                break
            }
        }
        localStorage.setItem('tempPlans', JSON.stringify(plans))
    }

    const onSubPlanChange = (val, type, targetKey, y) => {
        switch (type) {
            case 'editPrice': {
                const newPlans = [...plans]
                val = val.replace(/\D/g, '')
                newPlans[targetKey].subPlans[y].price = val
                setPlans(newPlans)
                break
            }
            case 'editDuration': {
                const newPlans = [...plans]
                val = val.replace(/\D/g, '')
                newPlans[targetKey].subPlans[y].duration = val
                setPlans(newPlans)
                break
            }
            case 'add': {
                const newPlans = [...plans]
                newPlans[targetKey].subPlans = [
                    ...newPlans[targetKey].subPlans,
                    {
                        price: '',
                        duration: '',
                        y: newPlans[targetKey].subPlans.length,
                    },
                ]
                setPlans(newPlans)
                break
            }
            case 'remove': {
                const newPlans = [...plans]
                let temp = []
                newPlans[targetKey].subPlans.forEach((element) => {
                    if (element.y > y)
                        temp = [
                            ...temp,
                            {
                                price: element.price,
                                duration: element.duration,
                                y: element.y - 1,
                            },
                        ]
                    else if (element.y < y)
                        temp = [
                            ...temp,
                            {
                                price: element.price,
                                duration: element.duration,
                                y: element.y,
                            },
                        ]
                })
                newPlans[targetKey].subPlans = temp
                setPlans(newPlans)
                localStorage.removeItem('tempPlans')
                break
            }
        }
        localStorage.setItem('tempPlans', JSON.stringify(plans))
    }

    const onNameChange = (val, targetKey) => {
        const newPlan = [...plans]
        newPlan[targetKey].name = val
        setPlans(newPlan)
        localStorage.setItem('tempPlans', JSON.stringify(plans))
    }

    const getChannelList = async () => {
        const allChannels = JSON.parse(localStorage.getItem('channelList'))
            ? JSON.parse(localStorage.getItem('channelList'))
            : []
        const paidChannels = allChannels.filter(
            (item) => item.membership === 'Paid'
        )
        let channelOptions = []
        for (const channel of paidChannels) {
            channelOptions = [
                ...channelOptions,
                {
                    value: channel.key,
                    label: channel.name,
                },
            ]
        }
        setChannelList(channelOptions)
        setPlans(fetchCached())
    }

    const onChannelChange = (id) => {
        const newPlan = [...plans]
        newPlan[activePane].channelId = id
        setPlans(newPlan)
        localStorage.setItem('tempPlans', JSON.stringify(plans))
    }

    useEffect(() => {
        getChannelList()
        console.log('Inside plan creation')
        return () => {
            clearTimeout(validateEntries)
        }
    }, [])

    return (
        <>
            <div
                style={{
                    minHeight: '550px',
                }}
            >
                <div
                    style={{
                        height: '550px',
                        overflowY: 'scroll',
                    }}
                >
                    <Typography.Paragraph strong>
                        Add your existing plans here!
                    </Typography.Paragraph>
                    <Tabs
                        type="editable-card"
                        onChange={(e) => {
                            setactivePane(e)
                        }}
                        defaultActiveKey={activePane}
                        activeKey={activePane}
                        onEdit={onPlanChange}
                        tabPosition="top"
                    >
                        {plans.map((item) => (
                            <TabPane
                                forceRender={true}
                                tab={`Plan ${item.key + 1}`}
                                key={item.key}
                            >
                                <div
                                    style={{
                                        marginTop: 20,
                                    }}
                                >
                                    {/* Plan Name section */}
                                    <Row gutter={[2, 2]}>
                                        <Col span={3}>
                                            <div
                                                style={{
                                                    marginLeft: 20,
                                                    marginTop: 10,
                                                    display: 'flex',
                                                    justifyContent: 'left',
                                                }}
                                            >
                                                <Typography.Text strong>
                                                    * Plan Name
                                                </Typography.Text>
                                            </div>
                                        </Col>
                                        <Col span={18}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'left',
                                                }}
                                            >
                                                <Input.TextArea
                                                    autoSize={{ maxRows: 4 }}
                                                    style={{ width: '70%' }}
                                                    value={item.name}
                                                    placeholder="Name of plan"
                                                    onChange={(e) =>
                                                        onNameChange(
                                                            e.target.value,
                                                            activePane
                                                        )
                                                    }
                                                />
                                            </div>
                                        </Col>
                                    </Row>
                                    <br />
                                    {/* Benefits section */}
                                    <Row gutter={[2, 2]}>
                                        <Col span={3}>
                                            <div
                                                style={{
                                                    marginLeft: 30,
                                                    marginTop: 10,
                                                    display: 'flex',
                                                    justifyContent: 'left',
                                                }}
                                            >
                                                <Typography.Text strong>
                                                    Benefits
                                                </Typography.Text>
                                            </div>
                                        </Col>
                                        <Col span={18}>
                                            {item.benefits.map((benefit) => (
                                                <>
                                                    <div
                                                        key={benefit.y}
                                                        style={{
                                                            display: 'flex',
                                                            justifyContent:
                                                                'left',
                                                        }}
                                                    >
                                                        <Input.TextArea
                                                            autoSize={{
                                                                maxRows: 4,
                                                            }}
                                                            style={{
                                                                width: '70%',
                                                            }}
                                                            value={
                                                                benefit.value
                                                            }
                                                            placeholder="Additional benefits, if any"
                                                            onChange={(e) =>
                                                                onBenefitsChange(
                                                                    e.target
                                                                        .value,
                                                                    'edit',
                                                                    activePane,
                                                                    benefit.y
                                                                )
                                                            }
                                                        />

                                                        <Space>
                                                            <Button
                                                                style={{
                                                                    marginLeft: 10,
                                                                    width: 80,
                                                                }}
                                                                disabled={
                                                                    item
                                                                        .benefits
                                                                        .length ===
                                                                    1
                                                                }
                                                                onClick={() =>
                                                                    onBenefitsChange(
                                                                        '',
                                                                        'remove',
                                                                        activePane,
                                                                        benefit.y
                                                                    )
                                                                }
                                                            >
                                                                Remove
                                                            </Button>
                                                        </Space>
                                                    </div>
                                                    {benefit.y ===
                                                    item.benefits.length - 1 ? (
                                                        <Button
                                                            type="primary"
                                                            style={{
                                                                marginTop:
                                                                    '10px',
                                                                width: 60,
                                                            }}
                                                            onClick={() =>
                                                                onBenefitsChange(
                                                                    '',
                                                                    'add',
                                                                    activePane,
                                                                    benefit.y
                                                                )
                                                            }
                                                        >
                                                            Add
                                                        </Button>
                                                    ) : (
                                                        <></>
                                                    )}
                                                </>
                                            ))}
                                        </Col>
                                    </Row>
                                    <br />
                                    <br />
                                    {/*  Subplans section */}
                                    {item.subPlans.map((subPlan) => (
                                        <Row gutter={[2, 2]} key={subPlan.y}>
                                            <Col span={3}>
                                                <div
                                                    style={{
                                                        marginLeft: 20,
                                                        marginTop: 8,
                                                        display: 'flex',
                                                        justifyContent: 'left',
                                                    }}
                                                >
                                                    <Typography.Text strong>
                                                        {`* Package ${
                                                            subPlan.y + 1
                                                        }`}
                                                    </Typography.Text>
                                                </div>
                                            </Col>
                                            <Col span={3}>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'left',
                                                    }}
                                                >
                                                    <Input
                                                        prefix="₹"
                                                        style={{ width: '90%' }}
                                                        value={subPlan.price}
                                                        placeholder="Amount in Rs."
                                                        onChange={(e) =>
                                                            onSubPlanChange(
                                                                e.target.value,
                                                                'editPrice',
                                                                activePane,
                                                                subPlan.y
                                                            )
                                                        }
                                                    />
                                                </div>
                                                {subPlan.y ===
                                                item.subPlans.length - 1 ? (
                                                    <Button
                                                        type="primary"
                                                        style={{
                                                            marginTop: '10px',
                                                            width: 60,
                                                        }}
                                                        onClick={() =>
                                                            onSubPlanChange(
                                                                '',
                                                                'add',
                                                                activePane,
                                                                subPlan.y
                                                            )
                                                        }
                                                    >
                                                        Add
                                                    </Button>
                                                ) : (
                                                    <></>
                                                )}
                                            </Col>
                                            <Col span={1}>
                                                <div
                                                    style={{
                                                        marginTop: 8,
                                                        display: 'flex',
                                                        justifyContent: 'left',
                                                    }}
                                                >
                                                    <Typography.Text strong>
                                                        For
                                                    </Typography.Text>
                                                </div>
                                            </Col>
                                            <Col span={2}>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'left',
                                                    }}
                                                >
                                                    <Input
                                                        style={{ width: '90%' }}
                                                        value={subPlan.duration}
                                                        placeholder="No. of Days"
                                                        onChange={(e) =>
                                                            onSubPlanChange(
                                                                e.target.value,
                                                                'editDuration',
                                                                activePane,
                                                                subPlan.y
                                                            )
                                                        }
                                                    />
                                                </div>
                                            </Col>
                                            <Col span={2}>
                                                <div
                                                    style={{
                                                        display: 'flex',
                                                        justifyContent: 'left',
                                                    }}
                                                >
                                                    <Dropdown
                                                        overlay={
                                                            <Menu
                                                                onClick={(e) =>
                                                                    onSubPlanChange(
                                                                        e.key,
                                                                        'editDuration',
                                                                        activePane,
                                                                        subPlan.y
                                                                    )
                                                                }
                                                            >
                                                                <Menu.Item key="7">
                                                                    7 Days
                                                                    (Weekly)
                                                                </Menu.Item>
                                                                <Menu.Item key="14">
                                                                    14 Days
                                                                    (Fortnightly)
                                                                </Menu.Item>
                                                                <Menu.Item key="30">
                                                                    30 Days
                                                                    (Monthly)
                                                                </Menu.Item>
                                                                <Menu.Item key="60">
                                                                    60 Days
                                                                </Menu.Item>
                                                                <Menu.Item key="90">
                                                                    90 Days
                                                                </Menu.Item>
                                                            </Menu>
                                                        }
                                                    >
                                                        <Button>
                                                            Days{' '}
                                                            <DownOutlined />
                                                        </Button>
                                                    </Dropdown>
                                                </div>
                                            </Col>
                                            <Col span={4}>
                                                <div
                                                    style={{
                                                        marginLeft: 15,
                                                        display: 'flex',
                                                        justifyContent: 'left',
                                                    }}
                                                >
                                                    <Space>
                                                        <Button
                                                            style={{
                                                                marginLeft: 10,
                                                                width: 80,
                                                            }}
                                                            disabled={
                                                                item.subPlans
                                                                    .length ===
                                                                1
                                                            }
                                                            onClick={() =>
                                                                onSubPlanChange(
                                                                    '',
                                                                    'remove',
                                                                    activePane,
                                                                    subPlan.y
                                                                )
                                                            }
                                                        >
                                                            Remove
                                                        </Button>
                                                    </Space>
                                                </div>
                                            </Col>
                                        </Row>
                                    ))}

                                    {/* Linked Channel section */}
                                    <div style={{ height: 25 }} />
                                    <Row gutter={[2, 2]}>
                                        <Col span={3}>
                                            <div
                                                style={{
                                                    marginLeft: 20,
                                                    marginTop: 5,
                                                    display: 'flex',
                                                    justifyContent: 'left',
                                                }}
                                            >
                                                <Typography.Text strong>
                                                    * Link Channels
                                                </Typography.Text>
                                            </div>
                                        </Col>
                                        <Col span={18}>
                                            <div
                                                style={{
                                                    display: 'flex',
                                                    justifyContent: 'left',
                                                }}
                                            >
                                                <Select
                                                    style={{ width: '70%' }}
                                                    mode="multiple"
                                                    value={item.channelId}
                                                    placeholder="Select paid channel to be linked"
                                                    onChange={(e) =>
                                                        onChannelChange(e)
                                                    }
                                                    options={channelList}
                                                />
                                                <Button
                                                    style={{ marginLeft: 15 }}
                                                    onClick={() =>
                                                        onChannelChange([])
                                                    }
                                                >
                                                    Clear
                                                </Button>
                                            </div>
                                            <Typography.Text
                                                style={{
                                                    marginLeft: '4px',
                                                    fontSize: '13px',
                                                    color: 'grey',
                                                }}
                                            >
                                                Users who subscribe to this plan
                                                will be added to above group(s)
                                            </Typography.Text>
                                        </Col>
                                    </Row>
                                </div>
                            </TabPane>
                        ))}
                    </Tabs>
                </div>
            </div>
            <AlertMessages MESSAGE={message} />
            <div style={{ width: '100%' }}>
                <Button
                    style={{ float: 'left' }}
                    onClick={() => {
                        setProgress(2)
                    }}
                >
                    Back
                </Button>
                <div style={{ float: 'right' }}>
                    <Button
                        onClick={() => {
                            postPlansAndChannels([])
                            setProgress(4)
                        }}
                    >
                        Skip for now
                    </Button>
                    <Button type="primary" onClick={() => validateEntries()}>
                        Next
                    </Button>
                </div>
            </div>
        </>
    )
}
