export const addChannel = (item) => {
    return {
        type: 'ADD_CHANNEL',
        payload: item,
    }
}

export const clearChannels = () => {
    return {
        type: 'CLEAR_CHANNELS',
    }
}
