import React, { useState } from 'react'
import { Avatar, Popover } from 'antd'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'

const UserProfile = () => {
    const history = useHistory()
    const userName = useSelector((state) => state.profile.influencerName)
    const photoUrl = useSelector((state) => state.profile.photoUrl)
    const [isVisible, setVisible] = useState(false)

    const handleVisibleChange = (visible) => {
        setVisible(visible)
    }

    return (
        <div className="gx-flex-row gx-align-items-center gx-mb-4 gx-avatar-row">
            <Popover
                placement="bottomRight"
                onClick={() => {
                    history.push('/about')
                }}
                visible={isVisible}
                onVisibleChange={handleVisibleChange}
            >
                <Avatar
                    src={photoUrl}
                    className="gx-size-40 gx-pointer gx-mr-3"
                    alt=""
                />
                <span className="gx-avatar-name">
                    {userName}
                    <i className="icon icon-chevron-down gx-fs-xxs gx-ml-2" />
                </span>
            </Popover>
        </div>
    )
}

export default UserProfile
