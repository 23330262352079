import { axiosTralkUserClient } from '../util/Api'
import {
    TRALK_AUTH,
    TRALK_GET_USER_PROFILE,
    TRALK_LINK_USER_FROM_TELEGRAM,
    TRALK_LOGOUT,
    TRALK_REFRESH,
    TRALK_VALIDATE,
} from '../util/ApiUrls'
import { queryExtract } from '../util/QueryToObject'

export const useProvideTralkUserAuth = () => {
    const getHeaders = () => {
        return JSON.parse(localStorage.getItem('credsTralkUser'))
    }

    const setHeaders = (accessToken, refreshToken) => {
        const authorization = `Bearer ${accessToken}`
        const refresh = refreshToken
        localStorage.setItem(
            'credsTralkUser',
            JSON.stringify({ authorization, refresh })
        )
        axiosTralkUserClient.defaults.headers.common = getHeaders()
    }

    const clearLocal = () => {
        localStorage.removeItem('credsTralkUser')
    }

    const userLogin = async (googleToken) => {
        try {
            axiosTralkUserClient.defaults.headers.common = {
                googleToken,
            }
            const data = await axiosTralkUserClient.post(TRALK_AUTH)
            if (data === undefined) {
                throw console.error()
            } else {
                const { accessToken, refreshToken } = data.data
                setHeaders(accessToken, refreshToken)
                axiosTralkUserClient.defaults.headers.common = getHeaders()
            }
        } catch (e) {
            console.log(e)
            clearLocal()
        }
    }

    const validate = async () => {
        if (getHeaders() === null) {
            return false
        }
        axiosTralkUserClient.defaults.headers.common = getHeaders()
        try {
            await axiosTralkUserClient.post(TRALK_VALIDATE)
            return true
        } catch {
            return await refresh()
        }
    }

    const linkUser = async () => {
        const query = queryExtract(localStorage.getItem('landingPageQuery'))
        try {
            if (query.userId) {
                const data = await axiosTralkUserClient.get(
                    TRALK_GET_USER_PROFILE
                )
                await axiosTralkUserClient.post(TRALK_LINK_USER_FROM_TELEGRAM, {
                    telegramUserId: query.userId,
                    tralkUserId: data.data.id,
                })
            }
        } catch (e) {
            console.log(e)
        }
    }

    const refresh = async () => {
        if (getHeaders() === null) {
            return false
        }
        axiosTralkUserClient.defaults.headers.common = getHeaders()
        try {
            const data = await axiosTralkUserClient.post(TRALK_REFRESH)
            setHeaders(data.data.accessToken, data.data.refreshToken)
            axiosTralkUserClient.defaults.headers.common = getHeaders()
            return true
        } catch {
            clearLocal()
            return false
        }
    }

    const userSignOut = async (callback) => {
        await axiosTralkUserClient.get(TRALK_LOGOUT)
        clearLocal()
        if (callback) callback()
    }

    return {
        userLogin,
        validate,
        refresh,
        linkUser,
        getHeaders,
        userSignOut,
    }
}
