import React from 'react'
import { Route, Switch } from 'react-router-dom'
import asyncComponent from 'util/asyncComponent'

const App = ({ match }) => {
    return (
        <div className="gx-main-content-wrapper">
            <Switch>
                <Route
                    path={`${match.url}dashboard`}
                    component={asyncComponent(() => import('./DashboardPage'))}
                />
                <Route
                    path={`${match.url}members`}
                    component={asyncComponent(() => import('./MembersPage'))}
                />
                <Route
                    path={`${match.url}channels`}
                    component={asyncComponent(() => import('./ChannelsPage'))}
                />
                <Route
                    path={`${match.url}discounts`}
                    component={asyncComponent(() => import('./DiscountsPage'))}
                />
                <Route
                    path={`${match.url}plans`}
                    component={asyncComponent(() => import('./PlansPage'))}
                />
                <Route
                    path={`${match.url}earnings`}
                    component={asyncComponent(() => import('./EarningsPage'))}
                />
                <Route
                    path={`${match.url}reminders`}
                    component={asyncComponent(() => import('./RemindersPage'))}
                />
                <Route
                    path={`${match.url}secretMessages`}
                    component={asyncComponent(() =>
                        import('./SecretMessagesPage')
                    )}
                />
                <Route
                    path={`${match.url}accessCodes`}
                    component={asyncComponent(() =>
                        import('./AccessCodesPage')
                    )}
                />
                <Route
                    path={`${match.url}setup`}
                    component={asyncComponent(() => import('./OnboardingPage'))}
                />
                <Route
                    path={`${match.url}profile`}
                    component={asyncComponent(() =>
                        import('./InfluencerLandingPage')
                    )}
                />
                <Route
                    path={`${match.url}about`}
                    component={asyncComponent(() => import('./ProfilePage'))}
                />
            </Switch>
        </div>
    )
}

export default App
