import React, { useState } from 'react'
import { Button, Space, Typography, Modal, Input, message } from 'antd'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import jQuery from 'jquery'
import Checkbox from 'antd/lib/checkbox/Checkbox'
import { CloseOutlined, CloseCircleFilled } from '@ant-design/icons'
import { Button as ButtonMui, styled } from '@mui/material'
import { useHistory } from 'react-router-dom'
import GetNumberCard from './getNumberCard'
import { TRALK_CHECK_COUPON } from '../../../util/ApiUrls'
import './ModalBorder.css'
import { axiosTralkUserClient } from '../../../util/Api'
import DiscountAndCoupon from './DiscountAndCoupon'
const options2 = (slides) => {
    return {
        dots: true,
        infinite: false,
        speed: 500,
        paddingBottom: '5px',
        slidesToShow: slides,
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: slides,
                    slidesToScroll: 1,
                    initialSlide: 1,
                },
            },
            {
                breakpoint: 150,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    initialSlide: 1,
                },
            },
        ],
    }
}

const StyledButton = styled(ButtonMui)({
    textTransform: 'none',
    ':hover': { backgroundColor: 'transparent' },
    height: '135px',
})

export const PlanClickedView = ({
    handleBack,
    setPricePlan,
    setPlanClicked,
    setDisclaimer,
    tralkLogin,
    planClicked,
    pricePlan,
    phoneNumber,
    getNumber,
    setGetNumber,
    setPhoneNumber,
    goToPayment,
    codeAvailable,
    codeDetails,
    freeCodeFlag,
}) => {
    const history = useHistory()
    const windowWidth = jQuery(window).width()
    const [couponModalVisible, setCouponModalVisible] = useState(false)
    const [couponValid, setCouponValid] = useState(true)
    const [couponCode, setCouponCode] = useState('')
    const [valid, setValid] = useState(false)
    const [couponDetails, setCouponDetails] = useState({})
    return (
        <>
            <Modal
                visible={couponModalVisible}
                width={windowWidth < 450 ? windowWidth : 450}
                footer={null}
                centered
                closable={false}
                style={{
                    marginBottom: 0,
                }}
                onCancel={() => setCouponModalVisible(false)}
            >
                <Typography.Paragraph style={{ fontSize: '25px' }}>
                    Enter coupon code
                </Typography.Paragraph>
                <Input
                    placeholder="Your Coupon Code"
                    style={{
                        borderRadius: '25px',
                        height: '50px',
                        borderColor: `${couponValid ? '' : 'red'}`,
                    }}
                    onChange={(e) => setCouponCode(e.target.value)}
                    value={couponCode}
                />
                <br />
                {couponValid ? (
                    <></>
                ) : (
                    <Typography.Text style={{ color: 'red' }}>
                        Please enter a valid coupon code
                    </Typography.Text>
                )}
                <br />
                <br />
                <Button
                    style={{
                        background: '#FFB800',
                        color: 'white',
                        borderRadius: '25px',
                        width: '100%',
                        height: '50px',
                    }}
                    onClick={async () => {
                        try {
                            const res = await axiosTralkUserClient.get(
                                `${TRALK_CHECK_COUPON}?planId=${
                                    planClicked().plan.id
                                }&subPlanId=${
                                    pricePlan()._id
                                }&discountCouponCode=${couponCode}`
                            )
                            localStorage.setItem('coupon', couponCode)

                            setValid(true)
                            setCouponDetails(res.data)
                            setCouponModalVisible(false)
                        } catch (e) {
                            setValid(false)
                            setCouponValid(false)
                            console.log(e)
                        }
                    }}
                >
                    Apply Offer
                </Button>
            </Modal>
            <div
                draggable={false}
                style={{
                    marginLeft: '5%',
                    marginTop: 10,
                    width: '90%',
                }}
            >
                <Space direction="horizontal">
                    <div
                        style={{
                            marginLeft: '5px',
                            width: windowWidth > 450 ? 360 : windowWidth * 0.8,
                        }}
                    >
                        <Typography.Text
                            style={{
                                fontFamily: 'Plus Jakarta Sans',
                                fontWeight: '700',
                                fontSize: '20px',
                                color: '#130F26',
                            }}
                        >
                            {planClicked().plan.planName}
                        </Typography.Text>
                    </div>
                    <Button
                        style={{
                            borderColor: 'transparent',
                            marginRight: 10,
                            boxShadow: 'none',
                        }}
                        shape="circle"
                        icon={<CloseOutlined />}
                        onClick={() => {
                            const planClickedCopy = {
                                ...planClicked(),
                            }
                            planClickedCopy.yes = false
                            handleBack()
                            setPricePlan(null)
                            setPlanClicked(planClickedCopy)
                        }}
                    />
                </Space>
                <p />
                <div
                    style={{
                        backgroundColor: '#F6F8F9',
                        boxShadow: 'none',
                        borderRadius: '20px',
                        paddingTop: '30px',
                        paddingBottom: '5px',
                        marginBottom: '20px',
                    }}
                >
                    <div style={{ paddingLeft: '20px' }}>
                        <Typography.Paragraph
                            style={{
                                fontFamily: 'Plus Jakarta Sans',
                                fontWeight: '700',
                                fontSize: '18px',
                                color: '#130F26',
                            }}
                        >
                            What you get
                        </Typography.Paragraph>
                        {planClicked().plan.details.map((detail) =>
                            detail.length > 0 ? (
                                <Typography.Paragraph
                                    style={{
                                        fontFamily: 'Plus Jakarta Sans',
                                        fontWeight: '500',
                                        fontSize: '14px',
                                        color: '#130F26',
                                        letterSpacing: '-0.01em',
                                    }}
                                    key={detail + planClicked().plan.id}
                                >
                                    • {detail}
                                </Typography.Paragraph>
                            ) : null
                        )}
                    </div>
                    <div style={{ height: 15 }} />
                    <Slider
                        {...options2(
                            planClicked().plan.pricePlans.length < 3
                                ? planClicked().plan.pricePlans.length
                                : 3
                        )}
                    >
                        {planClicked().plan.pricePlans.map((item) => {
                            return (
                                <StyledButton
                                    onClick={() => {
                                        const numberOfParams = location.pathname
                                            .split('profile/')[1]
                                            .split('/').length
                                        if (numberOfParams === 3) {
                                            history.push(item._id)
                                        } else if (numberOfParams < 3) {
                                            history.push(
                                                location.pathname +
                                                    '/' +
                                                    item._id
                                            )
                                        } else {
                                            history.push(
                                                location.pathname +
                                                    '../' +
                                                    item._id
                                            )
                                        }
                                        setPricePlan(item)
                                    }}
                                    key={item.price + item.numberOfDays}
                                >
                                    <div
                                        style={{
                                            backgroundColor: 'white',
                                            alignContent: 'center',
                                            borderRadius: '15px',
                                            borderStyle:
                                                pricePlan() &&
                                                item._id === pricePlan()._id
                                                    ? 'solid'
                                                    : 'hidden',
                                            borderColor: '#FFB800',
                                            width: '95px',
                                            height: '120px',
                                            marginTop: 5,
                                            paddingTop: '5px',
                                            marginLeft:
                                                (windowWidth > 450
                                                    ? 450
                                                    : windowWidth) *
                                                (planClicked().plan.pricePlans
                                                    .length > 2
                                                    ? 0.01
                                                    : planClicked().plan
                                                          .pricePlans.length ===
                                                      2
                                                    ? 0.1
                                                    : 0.3),
                                        }}
                                    >
                                        <DiscountAndCoupon
                                            item={item}
                                            valid={valid}
                                            planClicked={planClicked}
                                            pricePlan={pricePlan}
                                            couponDetails={couponDetails}
                                        />
                                    </div>
                                </StyledButton>
                            )
                        })}
                    </Slider>
                </div>
            </div>
            <div
                style={{
                    marginLeft: '5%',
                    marginBottom: windowWidth > 450 ? 10 : 250,
                    width: '90%',
                }}
            >
                <Typography.Text
                    style={{
                        fontFamily: 'Plus Jakarta Sans',
                        fontWeight: '700',
                        fontSize: '18',
                        color: '#130F26',
                    }}
                >
                    How it works
                </Typography.Text>
                <p />
                {[
                    'Choose the plan and complete the payment',
                    'You will get links for joining telegram channels',
                    'Click on the link and join the channels',
                ].map((item) => (
                    <Typography.Paragraph
                        key={item}
                        style={{
                            fontFamily: 'Plus Jakarta Sans',
                            fontWeight: '500',
                            fontSize: '14px',
                            color: '#130F26',
                        }}
                    >
                        • {item}
                    </Typography.Paragraph>
                ))}
            </div>
            {getNumber() ? (
                <GetNumberCard
                    setGetNumber={setGetNumber}
                    setNumber={setPhoneNumber}
                    phoneNumber={phoneNumber}
                    goToPayment={goToPayment}
                    codeAvailable={codeAvailable}
                    codeDetails={codeDetails}
                    freeCodeFlag={freeCodeFlag}
                />
            ) : (
                <div
                    style={{
                        position: windowWidth > 450 ? 'relative' : 'fixed',
                        bottom: 0,
                        borderRadius: '2px',
                        backgroundColor: '#FFFFFF',
                        boxShadow: 'none',
                        padding: '10px',
                        width: windowWidth < 450 ? windowWidth : 450,
                    }}
                >
                    <Checkbox
                        style={{
                            marginLeft: '4%',
                            marginTop: '5px',
                            marginBottom: '5px',
                        }}
                        onChange={(e) => setDisclaimer(e.target.checked)}
                    >
                        I accept the disclaimer
                    </Checkbox>
                    <div style={{ height: 10 }} />
                    <div
                        style={{
                            marginLeft: '4%',
                            display: 'flex',
                            justifyContent: 'space-between',
                            alignItems: 'center',
                        }}
                    >
                        {valid ? (
                            <div
                                style={{
                                    marginRight: '3%',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between',
                                    flex: '1',
                                    width: '30%',
                                }}
                            >
                                <img
                                    src={'/assets/images/valid-coupon.png'}
                                    style={{ float: 'left' }}
                                />
                                <div
                                    style={{
                                        marginLeft: '1%',
                                        flex: '1',
                                        display: 'flex',
                                        justifyContent: 'space-around',
                                        alignItems: 'center',
                                    }}
                                >
                                    {couponCode}
                                    <CloseCircleFilled
                                        onClick={() => {
                                            setValid(false)
                                            setCouponDetails({})
                                        }}
                                    />
                                </div>
                            </div>
                        ) : (
                            <Typography.Link
                                onClick={() => {
                                    if (pricePlan()?._id) {
                                        setCouponCode('')
                                        setCouponValid(true)
                                        setCouponModalVisible(true)
                                    } else message.error('Select a Sub Plan')
                                }}
                            >
                                Do you have a coupon?
                            </Typography.Link>
                        )}
                        <Button
                            type="ghost"
                            onClick={() => {
                                if (couponCode === '') {
                                    localStorage.removeItem('coupon')
                                }
                                tralkLogin()
                            }}
                            style={{
                                borderRadius: '25px',
                                borderColor: 'transparent',
                                backgroundColor: '#FFB800',
                                color: 'white',
                                height: 50,
                                width: '55%',
                                margin: '0',
                                // display: 'flex',
                                // justifyContent: 'center',
                                // paddingTop: '5px',
                            }}
                        >
                            Buy Plan
                        </Button>
                    </div>
                </div>
            )}
        </>
    )
}
