const initialState = {
    status: '',
    onboardingStatus: '',
    influencerId: '',
    influencerName: '',
    influencerAbout: [],
    influencerUsername: '',
    influencerMobileNumber: '',
    photoUrl: '',
    influencerLanding: '',
    influencerBotDetails: '',
    metrics: {
        totalMembers: {
            count: '0',
            growth: '0',
            growthGraph: [{ revenue: 0 }, { revenue: 0 }],
        },
        freeMembers: {
            count: '0',
            growth: '0',
            growthGraph: [{ revenue: 0 }, { revenue: 0 }],
        },
        paidMembers: {
            count: '0',
            growth: '0',
            growthGraph: [{ revenue: 0 }, { revenue: 0 }],
        },
    },
    topPlans: [
        { name: 'Plan 1', perc: 0, color: 'purple' },
        { name: 'Plan 2', perc: 0, color: 'green' },
        { name: 'Plan 3', perc: 0, color: 'yellow' },
        { name: 'Others', perc: 0, color: 'grey' },
    ],
}

const ProfileReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_PROFILE_ID': {
            return {
                ...state,
                influencerId: action.payload,
            }
        }
        case 'SET_ONBOARDING_STATUS': {
            return {
                ...state,
                onboardingStatus: action.payload,
            }
        }
        case 'SET_PROFILE_NAME': {
            return {
                ...state,
                influencerName: action.payload,
            }
        }
        case 'SET_PROFILE_PHOTO_URL': {
            return {
                ...state,
                photoUrl: action.payload,
            }
        }
        case 'SET_PROFILE_STATUS': {
            return {
                ...state,
                status: action.payload,
            }
        }
        case 'SET_PROFILE_METRICS': {
            return {
                ...state,
                metrics: action.payload,
            }
        }
        case 'SET_PROFILE_TOP_PLANS': {
            return {
                ...state,
                topPlans: action.payload,
            }
        }
        case 'SET_PROFILE_ABOUT': {
            return {
                ...state,
                influencerAbout: action.payload,
            }
        }
        case 'SET_PROFILE_USERNAME': {
            return {
                ...state,
                influencerUsername: action.payload,
            }
        }
        case 'SET_PROFILE_MOBILE': {
            return {
                ...state,
                influencerMobileNumber: action.payload,
            }
        }
        case 'SET_LANDING_PAGE_URL': {
            return {
                ...state,
                influencerLanding: action.payload,
            }
        }
        case 'SET_BOT_DETAILS': {
            return {
                ...state,
                influencerBotDetails: action.payload,
            }
        }
        default:
            return state
    }
}

export default ProfileReducer
