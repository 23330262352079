const environment = process.env.REACT_APP_ENV

const FIREBASE_STAGE_FRONTEND_CREDENTIALS = {
    apiKey: 'AIzaSyDsvvZpI4QmhrRg3N34yMlE1jfWrpNd5Ec',
    authDomain: 'itribe-dev.firebaseapp.com',
    projectId: 'itribe-dev',
}

const FIREBASE_FRONTEND_CREDENTIALS = {
    apiKey: 'AIzaSyDfVVEIz1f6Ix59ErzI8iWrHvhrxuymXro',
    authDomain: 'itribe.in',
    projectId: 'tralk-e4628',
}

export const FIREBASE_CONFIG = () => {
    if (environment === 'prod') {
        return FIREBASE_FRONTEND_CREDENTIALS
    } else {
        return FIREBASE_STAGE_FRONTEND_CREDENTIALS
    }
}
