import antdAR from 'antd/lib/locale-provider/ar_EG'
import saMessages from '../locales/ar_SA.json'

const saLang = {
    messages: {
        ...saMessages,
    },
    antd: antdAR,
    locale: 'ar',
}
export default saLang
