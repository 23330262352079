// Influencer auth and profile apis

export const INFLUENCER_AUTH = 'auth/'
export const INFLUENCER_LOGOUT = 'auth/logout'
export const INFLUENCER_VALIDATE = 'auth/validateToken'
export const INFLUENCER_REFRESH = 'auth/refreshToken'
export const INFLUENCER_FETCH_PROFILE = 'auth/profile'
export const INFLUENCER_FETCH_METRICS = 'chatsDashboard/getInfluencerMetrics'
export const INFLUENCER_FETCH_BOTS = '/influencer/onboarding/getBots'
export const INFLUENCER_ADD_BOT_TO_CHAT = 'influencer/onboarding/addBotsToChat'
export const INFLUENCER_FETCH_TELEGRAM_ADMIN_CHATS =
    'influencer/onboarding/chatsWithAdminRights'
export const INFLUENCER_CHECK_BOT_AVAILABILITY =
    'influencer/onboarding/checkUsername?username='
export const INFLUENCER_CREATE_BOT = 'influencer/onboarding/createBot'
export const INFLUENCER_ONBOARDING_STATE_UPDATE = 'auth/setOnboardingStatus'
export const INFLUENCER_GET_TOP_PLANS = 'subscriptionPlansDashboard/getTopPlans'
export const INFLUENCER_EDIT_ABOUT = 'influencer/update'
export const INFLUENCER_BOT_CREATION_STATUS =
    'influencer/onboarding/checkBotCreation'

// Influencer session creation apis

export const INFLUENCER_TELEGRAM_SESSION_AUTH = 'telegram/auth'
export const INFLUENCER_TELEGRAM_OTP_VALIDATE = 'telegram/auth/validateCode'
export const INFLUENCER_TELEGRAM_PASSWORD_VALIDATE =
    'telegram/auth/loginWith2FA'
export const INFLUENCER_TELEGRAM_SESSION_CHECK = '/telegram/auth/sessionActive'

// Tralk auth apis

export const TRALK_AUTH = 'auth/v1/google/'
export const TRALK_VALIDATE = 'auth/v1/validateToken'
export const TRALK_REFRESH = 'auth/v1/refreshToken'
export const TRALK_LOGOUT = 'auth/v1/logout'
export const TRALK_GET_USER_PROFILE = 'auth/v1/profile'

// Tralk landing page apis

export const TRALK_INFLUENCER_PROFILE =
    'api/telegram/influencer/landingPagePublic/'
export const TRALK_INFLUENCER_PROFILE_WITH_USERPLANS =
    'api/telegram/influencer/landingPage/'
export const TRALK_GET_PAYMENT_LINK = 'api/telegram/buySubPlan'
export const TRALK_LINK_USER_FROM_TELEGRAM = 'api/telegram/linkUser'
export const TRALK_GET_PAYMENT_STATUS = 'api/telegram/getPaymentDetails?'
export const TRALK_CHECK_COUPON =
    'api/telegram/getSubscriptionPlanCouponDetails'

// Dashboard apis

export const DASHBOARD_FETCH_CHATS = 'chatsDashboard/getInfluencerChats'
export const DASHBOARD_FETCH_MEMBERS = 'chatsDashboard/getAllMembers?'
export const DASHBOARD_FETCH_EXPIRING_MEMBERS =
    'subscriptionPlansDashboard/getNearlyEndingSubscriptions?'
export const DASHBOARD_SEND_REMINDER_SINGLE = 'dashboardExternal/sendReminder'

// Dashboard plans and sub plans

export const DASHBOARD_FETCH_PLANS = 'subscriptionPlans'
export const DASHBOARD_CREATE_PLANS = 'subscriptionPlans/'
export const DASHBOARD_UPDATE_PLANS = 'subscriptionPlans/'
export const DASHBOARD_DEACTIVATE_PLAN = 'subscriptionPlans/deactivate/'
export const DASHBOARD_DEACTIVATE_SUBPLAN =
    'subscriptionSubplan/deactivateSubplan/'
export const DASHBOARD_ADD_SUBPLAN = 'subscriptionSubplan/addSubplan/'
export const DASHBOARD_UPDATE_SUBPLAN = 'subscriptionSubplan/updateSubplan/'

// Access code apis

export const DASHBOARD_FETCH_ACCESS_CODES =
    'subscriptionAccessCodes/getAccessCodes?'
export const DASHBOARD_CREATE_ACCESS_CODES = 'subscriptionAccessCodes/'
export const DASHBOARD_UPDATE_ACCESS_CODES =
    'subscriptionAccessCodes/updateAccessCodes'

// Discount code apis

export const DASHBOARD_CREATE_DISCOUNT = 'discounts/addDiscount'
export const DASHBOAD_APPLY_DISCOUNT =
    'discountSubscription/addDiscountSubscription'
export const DASHBOARD_FETCH_DISCOUNTS =
    'discountSubscription/getDiscountsPerInfluencer'
export const DASHBOARD_DEACTIVATE_DISCOUNT =
    'discountSubscription/deactivateDiscountSubscription/'

// Secret Message apis

export const DASHBOARD_FETCH_SECRET_MESSAGES =
    'secretMessageStats/influencerMessages?'
export const DASHBOARD_CREATE_SECRET_MESSAGE =
    'secretMessage/createSecretMessage'
export const DASHBOARD_SEND_SECRET_MESSAGE = 'secretMessage/send'
export const DASHBOARD_DEACTIVATE_SECRET_MESSAGE = 'secretMessage/deactivate/'
export const DASHBOARD_ACTIVATE_SECRET_MESSAGE = 'secretMessage/activate/'
export const USER_SECRET_MESSAGE_ACCESS = 'secretMessage/secretMessageAccess/'
export const USER_GET_SECRET_MESSAGE_PAYMENT_LINK = 'secretMessage/'

// S3 media apis

export const MEDIA_UPLOAD_URL = 's3/presignedPutUrl?'
export const MEDIA_GET_URL = 's3/presignedGetUrl/'
