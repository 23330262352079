export const queryExtract = (querySearch) => {
    const query = querySearch.split('&')
    let extracted = ''
    for (const item of query) {
        const itemMod = item.split('=')
        if (extracted.length !== 0) {
            extracted = extracted + ','
        }
        extracted = extracted + `"${itemMod[0]}":"${itemMod[1]}"`
    }
    extracted = `{${extracted}}`
    return JSON.parse(extracted)
}
