import { useEffect, useState } from 'react'
import { axiosClient, axiosUserClient } from '../../../util/Api'
import { useHistory } from 'react-router-dom'
import { useProfileData } from '../../../dataAccess/ProfileData'
import {
    INFLUENCER_AUTH,
    INFLUENCER_LOGOUT,
    INFLUENCER_REFRESH,
    INFLUENCER_VALIDATE,
} from '../../../util/ApiUrls'

const date = new Date()

export const useProvideAuth = () => {
    const [authInfluencer, setAuthInfluencer] = useState(null)
    const [isLoadingInfluencer, setLoadingInfluencer] = useState(true)
    const [isLoading, setLoading] = useState(false)
    const history = useHistory()
    const initUrl = location.pathname
    const { setProfileDetails } = useProfileData()

    const fetchStart = () => {
        setLoading(true)
    }

    const fetchSuccess = () => {
        setLoading(false)
    }

    const getHeaders = () => {
        return JSON.parse(localStorage.getItem('creds'))
    }

    const setHeaders = (accessToken, refreshToken) => {
        const authorization = `Bearer ${accessToken}`
        const refresh = refreshToken
        const expire = date.getTime() + 1000 * 60 * 50
        localStorage.setItem(
            'creds',
            JSON.stringify({ authorization, refresh, expire })
        )
    }

    const clearLocal = () => {
        axiosClient.defaults.headers.common.Authorization = ''
        localStorage.removeItem('creds')
    }

    const userLogin = async (user) => {
        fetchStart()
        try {
            const data = await axiosClient.post(INFLUENCER_AUTH, user)
            if (data === undefined) {
                fetchSuccess()
                throw console.error()
            } else {
                const { accessToken, refreshToken } = data.data
                setHeaders(accessToken, refreshToken)
                axiosClient.defaults.headers.common = getHeaders()
                await getauthInfluencer()
            }
        } catch {
            setLoading(false)
            clearLocal()
        }
    }

    const userSignOut = async (callback) => {
        fetchStart()
        await axiosClient.get(INFLUENCER_LOGOUT)
        setAuthInfluencer(false)
        fetchSuccess()
        clearLocal()
        if (callback) callback()
    }

    const profileErrorChecker = async () => {
        const profileSuccess = await setProfileDetails()
        if (profileSuccess) {
            setAuthInfluencer(true)
            fetchSuccess()
        } else {
            userSignOut(() => {
                history.push('/')
            })
        }
    }

    const getauthInfluencer = async () => {
        fetchStart()
        await profileErrorChecker()
    }

    const validate = async () => {
        try {
            return await axiosClient.post(INFLUENCER_VALIDATE)
        } catch {
            return false
        }
    }

    const refresh = async () => {
        try {
            const data = await axiosClient.post(INFLUENCER_REFRESH)
            setHeaders(data.data.accessToken, data.data.refreshToken)
            axiosClient.defaults.headers.common = getHeaders()
            return validate()
        } catch {
            clearLocal()
            return false
        }
    }

    useEffect(() => {
        async function fetchData() {
            const creds = getHeaders()
            if (creds) {
                axiosClient.defaults.headers.common = creds
                let isValid = await validate()
                if (!isValid) {
                    isValid = await refresh()
                }
                if (isValid) {
                    await profileErrorChecker()
                } else {
                    setAuthInfluencer(false)
                }
            }
            setLoadingInfluencer(false)
        }
        if (
            (initUrl.includes('userSecret') ||
                initUrl.includes('profile') ||
                initUrl.includes('paymentStatus')) === false
        )
            fetchData()
        else {
            setAuthInfluencer(false)
            setLoadingInfluencer(false)
        }
    }, [])

    // Return the user object and auth methods
    return {
        isLoadingInfluencer,
        isLoading,
        authInfluencer,
        setAuthInfluencer,
        getauthInfluencer,
        userLogin,
        userSignOut,
        getHeaders,
    }
}

export const useProvideUserAuth = () => {
    const getHeaders = () => {
        return JSON.parse(localStorage.getItem('credsUser'))
    }

    const setHeaders = (accessToken, refreshToken) => {
        const authorization = `Bearer ${accessToken}`
        const refresh = refreshToken
        localStorage.setItem(
            'credsUser',
            JSON.stringify({ authorization, refresh })
        )
        axiosUserClient.defaults.headers.common = getHeaders()
    }
    return {
        getHeaders,
        setHeaders,
    }
}
