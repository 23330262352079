import { Typography } from 'antd'
import React, { useEffect, useState } from 'react'
import Testimonials from './Carousel'
import jQuery from 'jquery'
import { useHistory } from 'react-router-dom'
import { usePlansData } from '../../dataAccess/PlansData'
import AppNotificationContainer from '../../components/AppNotificationContainer'

const InfluencerLandingPage = () => {
    const { getInfluencerDetailsLandingPage } = usePlansData()
    const windowWidth = jQuery(window).width()
    const infoFromUrl = location.pathname.replace('/profile/', '').split('/')
    const influencerId = infoFromUrl[0]
    const [planSelect, setPlanSelect] = useState(false)
    const [influencerDetails, setInfluencerDetails] = useState(null)
    const [loading, setLoading] = useState(true)
    const history = useHistory()

    document.title = `iTribe${
        loading ? '' : ` - ${influencerDetails.influencer.name}`
    }`
    const profileFetcher = async () => {
        const profileDetails = await getInfluencerDetailsLandingPage(
            influencerId
        )
        setInfluencerDetails(profileDetails)
        setLoading(false)
    }

    useEffect(() => {
        profileFetcher()
    }, [])

    useEffect(() => {
        history.listen(() => {
            if (!location.pathname.includes(influencerId)) {
                history.push(`${location.pathname}${influencerId}/`)
            }
            if (infoFromUrl.length > 1 && infoFromUrl[1].length > 0) {
                setPlanSelect(true)
            } else {
                setPlanSelect(false)
                if (location.pathname[location.pathname.length - 1] !== '/') {
                    history.push(location.pathname + '/')
                }
            }
        })
    }, [history])

    return (
        <div
            style={{
                paddingTop: '3px',
                marginBottom: '25px',
                background:
                    'linear-gradient(270deg, #A859D0 -50%, #505FD3 110%)',
                width: windowWidth < 450 ? windowWidth : 450,
                marginLeft: windowWidth < 450 ? 0 : (windowWidth - 450) / 2,
            }}
        >
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=0"
            />
            {loading ? (
                <AppNotificationContainer loading={loading} />
            ) : (
                <>
                    {planSelect ? null : (
                        <div className="gx-text-center">
                            <div
                                style={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                }}
                            >
                                <img
                                    style={{
                                        borderRadius: '50%',
                                        marginTop: 15,
                                        width: '70px',
                                        height: '70px',
                                    }}
                                    src={
                                        influencerDetails.influencer?.photoUrl
                                            ? influencerDetails.influencer
                                                  .photoUrl
                                            : '/assets/images/placeholder.jpg'
                                    }
                                />
                            </div>
                            <p />
                            <Typography.Paragraph
                                style={{
                                    fontFamily: 'Plus Jakarta Sans',
                                    fontWeight: '700',
                                    fontSize: '20px',
                                    color: '#FFFFFF',
                                }}
                            >
                                {influencerDetails.influencer.name}
                            </Typography.Paragraph>
                        </div>
                    )}
                    <div
                        style={{
                            borderRadius: '0px',
                            borderTopLeftRadius: '24px',
                            borderTopRightRadius: '24px',
                            background:
                                'linear-gradient(180deg, #FFFFFF 67.69%, #F6F8F9 80.65%)',
                            paddingLeft: '0px',
                            paddingRight: '0px',
                            paddingTop: '30px',
                        }}
                    >
                        {planSelect ||
                        influencerDetails.influencer.about.length ===
                            0 ? null : (
                            <div
                                style={{
                                    marginLeft: '5%',
                                    width: '90%',
                                }}
                            >
                                <Typography.Paragraph
                                    style={{
                                        fontFamily: 'Plus Jakarta Sans',
                                        fontWeight: '600',
                                        fontSize: '16px',
                                        color: '#130F26',
                                    }}
                                >
                                    About
                                </Typography.Paragraph>
                                <Typography.Paragraph
                                    style={{
                                        fontFamily: 'Plus Jakarta Sans',
                                        fontWeight: '500',
                                        fontSize: '14px',
                                        color: '#130F26',
                                        letterSpacing: '-0.01em',
                                    }}
                                >
                                    {influencerDetails.influencer.about}
                                </Typography.Paragraph>
                                <hr style={{ width: '95%' }} />
                            </div>
                        )}
                        <div style={{ height: 10 }} />
                        <Testimonials
                            props={{
                                plans: influencerDetails.plans,
                                influencerId: influencerId,
                                setPlanSelect: setPlanSelect,
                            }}
                        />
                    </div>
                </>
            )}
        </div>
    )
}
export default InfluencerLandingPage
