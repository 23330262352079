import { Alert } from 'antd'
import React from 'react'

export const AlertMessages = ({ props, MESSAGE, createBot }) => {
    switch (MESSAGE) {
        // session creation codes
        case 'INVALID PHONE NUMBER':
            return (
                <>
                    <Alert
                        message="Please enter correct 10 digit phone number"
                        type="error"
                    />
                </>
            )
        case 'INCORRECT OTP':
            return (
                <>
                    <Alert
                        message="OTP/Password entered is incorrect"
                        type="error"
                    />
                </>
            )
        // bot creation codes
        case 'USERNAME UNAVAILABLE':
            return (
                <>
                    <Alert message="Username is taken" type="error" />
                </>
            )
        case 'USERNAME AVAILABLE':
            return (
                <>
                    <Alert message="Username is available" type="success" />
                </>
            )
        case 'END_WITH_BOT':
            return (
                <>
                    <Alert
                        message="Bot username needs to end with bot"
                        type="error"
                    />
                </>
            )
        case 'BOTNAME_REQUIRED':
            return (
                <>
                    <Alert message="Bot Name can't be empty" type="error" />
                </>
            )
        case 'BOTNAME_INVALID':
            return (
                <>
                    <Alert message="Bot name and username can only contain alphanumeric characters and underscore" />
                </>
            )
        case 'CREATING_BOT':
            return (
                <>
                    <Alert
                        message="Bot creation can take 30-50 seconds. Please stay on the page."
                        type="info"
                    />
                </>
            )
        // plan validation codes
        case 'VALIDATION_PLAN_NAME':
            return (
                <Alert
                    message="Plan name must be atleast 4 characters"
                    type="error"
                />
            )
        case 'VALIDATION_PLAN_PRICE':
            return <Alert message="Package price needed" type="error" />
        case 'VALIDATION_PLAN_DURATION':
            return <Alert message="Package duration needed" type="error" />
        case 'VALIDATION_PLAN_CHANNEL':
            return (
                <Alert
                    message="Please link a channel to the plan"
                    type="error"
                />
            )
        case 'VALIDATION_SUBPLAN':
            return (
                <Alert message="Atleast one active plan needed" type="error" />
            )
        case 'VALIDATION_PLAN_SUCCESS':
            return <Alert message="Validated!" type="success" />
        case 'ERROR':
            return <Alert message="Some exception has occured" type="error" />
        case 'TIME_EXCEEDED':
            return (
                <Alert
                    message="Bot creation is taking too long. Please wait a while and refresh this page."
                    type="error"
                />
            )
        default:
            return <div style={{ height: 52 }} />
    }
}
