const initialState = {
    channels: [],
}

const ChannelsReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'ADD_CHANNEL': {
            return {
                ...state,
                channels: [...state.channels, action.payload],
            }
        }
        case 'CLEAR_CHANNELS': {
            return {
                ...state,
                channels: [],
            }
        }
        default:
            return state
    }
}

export default ChannelsReducer
