import { useDispatch } from 'react-redux'
import { axiosClient } from '../util/Api'
import {
    setProfileId,
    setProfileName,
    setProfilePhoto,
    setProfileMetrics,
    setProfileTopPlans,
    setProfileStatus,
    setOnboardingStatus,
    setProfileUsername,
    setProfileAbout,
    setProfileMobileNumber,
    setInfluencerBotDetails,
    setLandingPageUrl,
} from '../appRedux/actions'
import {
    INFLUENCER_FETCH_BOTS,
    INFLUENCER_FETCH_METRICS,
    INFLUENCER_FETCH_PROFILE,
    INFLUENCER_GET_TOP_PLANS,
} from '../util/ApiUrls'

const environment = process.env.REACT_APP_ENV

const percHelper = (item) => {
    if (typeof array === 'undefined') {
        return 0
    }
    if (item.length < 2) {
        return '0'
    } else {
        return (
            '' +
            Math.round(
                ((item[item.length - 1] - item[item.length - 2]) * 100) /
                    item[item.length - 2]
            )
        )
    }
}

const graphHelper = (array) => {
    if (typeof array === 'undefined') {
        return []
    }
    let data = []
    array.forEach((element) => {
        data = [...data, { revenue: element }]
    })
    return data
}

export const useProfileData = () => {
    const dispatch = useDispatch()

    const setUserDetails = async (data) => {
        dispatch(setProfileName(data.firstName))
        dispatch(setProfilePhoto(data.photoUrl ? data.photoUrl : ''))
        dispatch(setProfileUsername(data.username ? data.username : ''))
        dispatch(setProfileAbout(data.about))
        dispatch(
            setProfileMobileNumber(data.mobileNumber ? data.mobileNumber : '')
        )
        dispatch(setProfileId(data.userId))
        dispatch(setProfileStatus(data.status))
        dispatch(setOnboardingStatus(data.onboardingStatus))
        const bot = await axiosClient.get(INFLUENCER_FETCH_BOTS)
        if (bot.data.length > 0) {
            dispatch(
                setInfluencerBotDetails({
                    botName: bot.data[0].name,
                    botTelegramUsername: bot.data[0].username,
                })
            )
            const botUsernameForLanding =
                bot.data[0].username[bot.data[0].username.length - 4] === '_'
                    ? bot.data[0].username.slice(0, -4)
                    : bot.data[0].username.slice(0, -3)
            dispatch(
                setLandingPageUrl(
                    environment === 'prod'
                        ? `influencers.itribe.in/profile/${botUsernameForLanding}/`
                        : `${
                              window.location.origin.split('//')[1]
                          }/profile/${botUsernameForLanding}/`
                )
            )
        }
    }

    const setUserMetrics = (data) => {
        try {
            dispatch(
                setProfileMetrics({
                    totalMembers: {
                        count: data?.totalMembers?.count
                            ? data?.totalMembers?.count
                            : 0,
                        growthGraph: graphHelper(data?.totalMembers?.growth),
                        growth: percHelper(data?.totalMembers?.growth),
                    },
                    freeMembers: {
                        count: data?.freeMembers?.count
                            ? data?.freeMembers?.count
                            : 0,
                        growthGraph: graphHelper(data?.freeMembers?.growth),
                        growth: percHelper(data?.freeMembers?.growth),
                    },
                    paidMembers: {
                        count: data?.paidMembers?.count
                            ? data?.paidMembers?.count
                            : 0,
                        growthGraph: graphHelper(data?.paidMembers?.growth),
                        growth: percHelper(data?.paidMembers?.growth),
                    },
                })
            )
        } catch (e) {
            console.log(e)
        }
    }

    const setUserTopPlans = (data) => {
        const colors = ['purple', 'yellow', 'green', 'grey']
        let plans = []
        let sum = 0
        for (let i = 0; i < Math.min(data?.length, 3); i++) {
            plans = [
                ...plans,
                {
                    name: data[i]?.planName,
                    perc: Math.round(data[i]?.percentage),
                    color: colors[i],
                },
            ]
            sum = sum + Math.round(data[i]?.percentage)
        }
        const others = 100 - sum
        if (others > 0 && data.length > 3) {
            plans = [
                ...plans,
                { name: 'Others', perc: others, color: colors[3] },
            ]
        } else {
            plans[plans.length - 1].perc = plans[plans.length - 1].perc + others
        }
        dispatch(setProfileTopPlans(plans))
    }

    const setProfileDetails = async () => {
        try {
            const loggedInUser = await axiosClient.get(INFLUENCER_FETCH_PROFILE)
            setUserDetails(loggedInUser.data)
        } catch {
            console.log('profile error')
            return false
        }
        try {
            const metrics = await axiosClient.get(INFLUENCER_FETCH_METRICS)
            setUserMetrics(metrics.data)
        } catch {
            console.log('metrics error')
            return false
        }
        const plans = await axiosClient.get(INFLUENCER_GET_TOP_PLANS)
        setUserTopPlans(
            plans.data.length > 0
                ? plans.data
                : [{ planName: 'Others', percentage: 100 }]
        )
        return true
    }

    return {
        setProfileDetails,
    }
}
