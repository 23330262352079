import { combineReducers } from 'redux'
import { connectRouter } from 'connected-react-router'
import Settings from './Settings'
import Common from './Common'
import Members from './Members'
import Profile from './Profile'
import Channels from './Channels'

const createRootReducer = (history) =>
    combineReducers({
        router: connectRouter(history),
        settings: Settings,
        common: Common,
        member: Members,
        profile: Profile,
        channel: Channels,
    })

export default createRootReducer
